%input-xxl {
  @include button-size(22px, 38px, $font-size-h3, 1.30, $btn-border-radius-large);
  @media (max-width: $screen-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
    transition: all .22s ease-in-out;
  }
}

.btn-xl {
  @extend %input-xxl;
}

.btn-up {
  text-transform: uppercase;
}

.btn-primary-dark {
  @include button-variant($btn-primary-dark-color, $btn-primary-dark-bg, $btn-primary-dark-border);
}

.btn-secondary {
  @include button-variant(#fff, #00affd, #00affd);
}

.btn-icon {
  background-repeat: no-repeat;
  background-position: center center;
  &.btn-lg {
    min-height: $input-height-large;
  }
  &--left {
    background-position: center left;
    padding-left: 56px + ($padding-large-horizontal);
  }
  &--right {
    background-position: center right;
    padding-right: 56px + ($padding-large-horizontal);
  }
  &--arrow-right {
    &,
    &:active {
      @include img-retina('../images/btn-icon-arrow-right.png', '../images/btn-icon-arrow-right@2x.png', 56px, 51px);
    }
  }
  &--arrow-left {
    &,
    &:active {
      @include img-retina('../images/btn-icon-arrow-left.png', '../images/btn-icon-arrow-left@2x.png', 150px, 70px);
    }
  }
  &--mail {
    &,
    &:active {
      @include img-retina('../images/btn-icon-mail.png', '../images/btn-icon-mail@2x.png', 56px, 51px);
    }
  }
}

.btn-shadow {
  box-shadow: 0 4px 0 0 rgba(3, 160, 194, 0.5);
}

.btn-upload {
  @include img-retina('../images/btn-icon-resume.png', '../images/btn-icon-resume@2x.png', 70px, 90px);
  position: relative;
  display: block;
  border: 2px dashed #fff;
  width: 100%;
  min-height: 180px;
  background-repeat: no-repeat ;
  background-position: center 25px;
  padding: 25px;
  margin-bottom: 20px;
  &::before {
    font-size: 21px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // content: "DROP YOUR RESUME HERE";
    padding-bottom: 25px;
    display: block;
    text-align: center;
    user-select: none;
  }
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    &::before {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }

  label {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    /* top: 110px; */
    padding-top: 110px;
    position: relative;
    font-size: 18px;
    font-weight: 400;
  }
}

#filename {
  display: flex;
  justify-content: center;
  top: 0;
  position: relative;
  padding-bottom: 20px;
  font-size: 18px;
}

.btn-group-justified-custom {
  table-layout: auto;

}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  width: 90%;
}



@media (max-width: $screen-xs-max) {
  .btn-xs-block {
    display: block;
    width: 100%;
  }
}
