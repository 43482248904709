$icon-font-path: '../fonts/';
$font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-color-pricing: #536B8A;
$navbar-inverse-bg: rgba(7, 7, 22, 0.80);
$navbar-inverse-border: #fff;
$navbar-inverse-color: #fff;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-active-color: #fff;
$navbar-inverse-link-active-bg: transparent;
$navbar-margin-bottom: 0;
$navbar-inverse-toggle-border-color: transparent;
$navbar-inverse-toggle-hover-bg: transparent;
$brand-primary: #1AB5FF;
$brand-warning: #f5a623;
$brand-primary-dark: #1eb3ff;
$text-color: #4a4a4a;

$btn-border-radius-small: 3px;
$btn-border-radius-base: 3px;
$btn-border-radius-large: 3px;

$btn-primary-bg: $brand-primary;
$btn-primary-border: #4a90e2;
$btn-primary-color: #fff;

$btn-primary-dark-bg: #007ad9;
$btn-primary-dark-border: #fff;
$btn-primary-dark-color: #fff;

$font-size-h1: 75px;
$font-size-h2: 50px;
$font-size-h3: 19px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$headings-font-weight: 300;
$letter-spacing-h3: 0.7px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  ),
  6: (
    x: ($spacer-x * 6),
    y: ($spacer-y * 6)
  ),
  7: (
    x: ($spacer-x * 7),
    y: ($spacer-y * 7)
  ),
  8: (
    x: ($spacer-x * 8),
    y: ($spacer-y * 8)
  )
) !default;
