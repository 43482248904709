@at-root {
    // Scoped variables just for this file
    $screen-sm-min: 768px;
    $screen-xs-max: 769px;
    .navbar {
        padding-top: 0px;
        &--grow {
            padding-top: 15px;
        }
    }
    .navbar-header {
        margin-right: 100px !important;
        @media screen and (min-width: $screen-xs-max) and (max-width: 1240px) {
            margin-right: 50px !important;
        }
        @media screen and (max-width: $screen-sm-min) {
            margin-right: 0 !important;
            margin-left: 0 !important;
            width: 100%;
        }
    }
    .navbar-brand {
        //@include text-hide();
        //@include img-retina('../images/logo-light.png', '../images/logo-light@2x.png', 32px, 32px);
        width: 32px;
        height: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: height 0.3s;
        display: flex;
        align-items: center;
        &__logoicon {
            width: 48px;
            @media screen and (max-width: $screen-sm-max) {
                width: 90px;
            }
        }
        &__logolink {
            display: inline-block !important;
            padding-left: 30px !important;
            padding-top: 20px !important;
        }
        &__label {
            margin-bottom: 0;
            margin-left: 10px;
            color: white;
            &--hide {
                display: none;
            }
            @media screen and (max-width: $screen-sm-min) {
                margin-left: 0;
            }
            @media screen and (max-width: $screen-sm-max) {
                font-size: 12px;
            }
        }
        @media (max-width: $screen-sm-min) {
            display: none !important;
            padding: 0;
        }
        @media (min-width: $screen-xs-max) {
            border-bottom: 4px solid transparent;
            background-size: 32px;
            &.active {
                //border-color: $brand-primary;
            }
            .navbar-shrink & {
                background-size: 64px;
                width: 64px;
                height: 80px;
            }
            .navbar>.container & {
                margin-left: 7px;
                margin-right: 0;
                margin-top: 2px;
            }
        }
        @media screen and (min-width: $screen-xs-max) and (max-width: 768px) {
            &__label {
                display: none;
            }
        }
    }
    .sidebar-brand {
        @include text-hide();
        @include img-retina('../images/logo-light.png', '../images/logo-light@2x.png', 32px, 32px);
        width: 32px;
        height: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: height 0.3s;
        float: left;
    }
    .navbar-nav {
        .request-btn {
            padding: 10px 20px !important;
            margin-top: 20px;
            margin-left: 20px;
            border-radius: 2px;
            transition: 200ms ease-in;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            letter-spacing: 1px;
            &:hover,
            &:active,
            &:focus,
            &visited {
                color: white !important;
                background-color: $brand-primary !important;
            }
            &--hide {
                display: none;
            }
        }
        @media screen and (min-width: $screen-xs-max) and (max-width: 1240px) {
            font-size: 0.9em;
            .request-btn {
                // display: none;
                font-family: 'Roboto', sans-serif;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
    }
    .navbar-nav-link {
        transition: all 0.3s;
        color: white;
        letter-spacing: 0.8px;
        -webkit-font-smoothing: antialiased;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        @media (max-width: 1280px) {
            font-size: 12px;
        }
        @media (max-width: $screen-sm-max) {
            font-size: 9px;
        }
        &:hover,
        &:focus {
            background-color: transparent !important;
            color: darken(white, 20%) !important;
        }
        &--primary {
            color: white;
            background-color: $brand-primary;
            text-transform: none;
            padding: 5px 20px;
            box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.5);
            border: 1px solid white;
        }
    }
    @media (max-width: $screen-sm-min) {
        .sb-slidebar {
            background-color: #070716;
            height: 100%;
            overflow-y: auto;
            position: fixed;
            top: 0;
            z-index: 0;
            display: none;
            .navbar-nav {
                margin: 0;
            }
            .navbar-nav-link {
                font-size: 18px;
                color: #fff;
                line-height: 14px;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
                padding: 20px 30px; //border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                &.sidebar-brand-wrap {
                    @include clearfix();
                    padding-top: 0;
                    padding-bottom: 0;
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                }
            }
        }
        .navbar-header .navbar-brand {
            width: calc(100% - 120px);
            padding: 0;
            margin-right: 60px;
            float: right;
        }
        .sb-active {
            .navbar-toggle .icon-bar {
                background-color: $brand-primary;
            }
            .navbar-inverse {
                border-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
    @media screen and (max-width: $screen-sm-min) {
        .navbar-toggle {
            display: block !important;
            float: left;
            z-index: 999;
        }
        .navbar-nav>li {
            float: none;
        }
    }
    @media (min-width: $screen-xs-max) {
        .navbar-toggle {
            display: none !important;
        }
        .navbar-nav .navbar-nav-link {
            padding-bottom: 11px;
            border-bottom: 4px solid transparent; //transition: border-color 0.5s ease-in-out;
        }
        .navbar-nav .active .navbar-nav-link {
            //border-color: $brand-primary;
        }
        .navbar-shrink {
            .navbar-nav-link {
                padding-top: 30px;
                padding-bottom: 26px;
            }
        }
    }
    #blue-slider {
        &-container {
            position: relative;
            display: flex;
            justify-content: space-between;
            @media (min-width: $screen-xs-max) {
                padding-left: 0px;
                padding-right: 0px;
            }
            @media screen and (max-width: $screen-sm-min) {
                padding-left: 10px;
                padding-right: 0;
                justify-content: flex-start;
                margin: 0 !important;
                width: 100%;
            }
        }
        position: absolute;
        width: 64px;
        background: $brand-primary;
        height: 3px !important;
        bottom: 0px;
        transition: all .2s linear;
        -webkit-transition: all .2s linear;
        -moz-transition: all .2s linear;
        display: none;
        &.shrink {
            width: 32px;
        }
        $padding: 0px;
        $brand: 235px;
        $overview: 495px;
        $howItWorks: 595px;
        $pricing: 698px;
        $contactUs: 780px;
        $team: 856px;
        $careers: 930px;
        @media (min-width: $screen-xs-max) {
            $left: 7px;
            $right: 0px;
            display: block;
            left: $left+$padding;
            &.overview--slide {
                $offset: $left+$right+$brand+$padding;
                left: $offset;
                width: $overview;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.how-it-works--slide {
                $offset: $left+$right+$brand+$padding+$overview;
                left: $offset;
                width: $howItWorks;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.pricing--slide {
                $offset: $left+$right+$brand+$padding+$howItWorks+$overview;
                left: $offset;
                width: $pricing;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.contact-us--slide {
                $offset: $left+$right+$brand+$padding+$howItWorks+$pricing+$overview;
                left: $offset;
                width: $contactUs;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.team--slide {
                $offset: $left+$right+$brand+$padding+$howItWorks+$pricing+$contactUs+$overview;
                left: $offset;
                width: $team;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.careers--slide {
                $offset: $left+$right+$brand+$padding+$howItWorks+$pricing+$contactUs+$team+$overview;
                left: $offset;
                width: $careers;
                &.shrink {
                    left: $offset - 32px;
                }
            }
        }
        @media (min-width: $screen-xs-max) {
            $left: 105px;
            $right: 50px;
            left: $left+$padding;
            &.overview--slide {
                $offset: $left+$right+$brand+$padding;
                left: $offset;
                width: $overview;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.how-it-works--slide {
                $offset: $padding+$left+$brand+$right+$overview;
                left: $offset;
                width: $howItWorks;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.pricing--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$overview;
                left: $offset;
                width: $pricing;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.contact-us--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$overview;
                left: $offset;
                width: $contactUs;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.team--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$contactUs+$overview;
                left: $offset;
                width: $team;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.careers--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$contactUs+$team+$overview;
                left: $offset;
                width: $careers;
                &.shrink {
                    left: $offset - 32px;
                }
            }
        }
        @media (min-width: $screen-xs-max) {
            $left: 149px;
            $right: 100px;
            left: $left+$padding;
            &.overview--slide {
                $offset: $left+$brand+$right+$padding;
                left: $offset;
                width: $overview;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.how-it-works--slide {
                $offset: $padding+$left+$brand+$right+$overview;
                left: $offset;
                width: $howItWorks;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.pricing--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$overview;
                left: $offset;
                width: $pricing;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.contact-us--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$overview;
                left: $offset;
                width: $contactUs;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.team--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$contactUs+$overview;
                left: $offset;
                width: $team;
                &.shrink {
                    left: $offset - 32px;
                }
            }
            &.careers--slide {
                $offset: $padding+$left+$brand+$right+$howItWorks+$pricing+$contactUs+$team+$overview;
                left: $offset;
                width: $careers;
                &.shrink {
                    left: $offset - 32px;
                }
            }
        }
    }
    .dropbtn {
        background-color: none;
        color: white;
        padding: 16px;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }
    .dropdown {
        position: relative;
        display: inline-block;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: rgba(7, 7, 22, 0.80);
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        right: -63px;
        @media(max-width: $screen-sm-min) {
            visibility:visible;
            text-align: left;
            right: 158px;
        }
    }
    .dropdown-content a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    .dropdown-content a:hover {
        background-color: $brand-primary;
    }
    .dropdown:hover .dropdown-content {
        display: block;
    }
    .dropdown:hover .dropbtn {
        background-color: none;
    }
    .navbar-brand-main {
        @media screen and (max-width: $screen-sm-min) {
            display: block !important;
            margin-right: 0 !important;
            position: absolute;
            width: 100% !important;
            text-align: center;
            top: 15px;
            img {
                display: none;
            }
        }
    }
    .navbar-hidden-btn {
        transition: all 0.3s;
        color: white;
        background-color: $brand-primary;
        &:hover,
        &:visited,
        &:active,
        &:focus {
            background-color: $brand-primary !important;
            color: white !important;
        }
    }
}

.buffer-line {
    margin-top: -18px;
    display: block;
}

.t5 {
    font-size: 14pt !important;
    color: #666666 !important;
    font-weight: bold;
}

.indent-1 {
  padding-left: 20px;
}

.indent-2 {
  padding-left: 40px;
}
// end of @at-root