.footer {
  background: #0b0b21;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.20);
  color: #fff;
  font-size: 15rpx;
  line-height: 14px;
  padding: 40px 40px;
  hr {
    border-color: transparentize(#fff, 0.8);
  }
}

.footer-address {
  ul{
    font-size: 15px;
    line-height: 1.2;
    list-style: none;
    margin: 0px;

    padding: 0px;
    margin-bottom: 30px;
    padding-top: 5px;
  }
}

.footer-logo-img {
  background: transparent no-repeat center center;
  @include text-hide;
  @include img-retina('../images/logo-white.png', '../images/logo-white@2x.png', 60px, 57px);
  background-size: contain;
  width: 60px;
  height: 57px;
}

.footer-text {
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
  &--light {
    color: rgba(#fff, 0.5);
  }
  &--dark {
    color: rgba(#fff, 0.7);
  }
}

.footer-link--gray {
  color: rgba(#fff, 0.5);
}

@media (max-width: $screen-xs-max) {
  .footer-logo-media {
    margin-bottom: 50px;
  }
  .footer-list {
    margin-bottom: 30px;
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    padding: 60px 0;
  }
}
