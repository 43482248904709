@import "../variables";

.map-pulse {
   display: block;
   background: $brand-primary;
   position: absolute;

   margin: 100px auto 0;
   width: 4px;
   height: 4px;

   border-radius: 50%;
   background:  rgba($brand-primary, 0.8);
   box-shadow: 0 0 0 rgba($brand-primary, 0.4);

   animation: mapPulse 1.7s infinite;

  &-text {
     display: block;

     padding: 50px 0 0 0;

     font-family: "Source Sans", sans-serif;
     text-align: center;
     color: rgba(0, 0, 0, 0.7);
  }

  @-webkit-keyframes mapPulse {
     0% {
        -webkit-box-shadow: 0 0 0 0 rgba($brand-primary, 0.4);
     }
     70% {
        -webkit-box-shadow: 0 0 0 26px rgba($brand-primary, 0);
     }
     100% {
        -webkit-box-shadow: 0 0 0 0 rgba($brand-primary, 0);
     }
  }

  @keyframes mapPulse {
     0% {
        -moz-box-shadow: 0 0 0 0 rgba($brand-primary, 0.4);
        box-shadow: 0 0 0 0 rgba($brand-primary, 0.4);
     }
     70% {
        -moz-box-shadow: 0 0 0 26px rgba($brand-primary, 0);
        box-shadow: 0 0 0 26px rgba($brand-primary, 0);
     }
     100% {
        -moz-box-shadow: 0 0 0 0 rgba($brand-primary, 0);
        box-shadow: 0 0 0 0 rgba($brand-primary, 0);
     }
  }

  &__1 {
    top: 15px;
    left: 30px;
    animation: mapPulse 1.2s infinite;
  }

  &__2 {
    bottom: 55px;
    left: 60px;
    animation: mapPulse 1.3s infinite;
  }

  &__3 {
    bottom: 55px;
    right: 160px;
    animation: mapPulse 2.3s infinite;
  }
  &__4 {
    top: 155px;
    left: 140px;
    animation: mapPulse 1.8s infinite;
  }

  &__5 {
    top: 23px;
    right: 100px;
    animation: mapPulse 1.5s infinite;
  }

  &__6 {
    top: 31px;
    right: 60px;
    animation: mapPulse 3.0s infinite;
  }

  &__7 {
    bottom: 69px;
    right: 90px;
    animation: mapPulse 3.0s infinite;
  }

  &__8 {
    bottom: 95px;
    right: 120px;
    animation: mapPulse 3.0s infinite;
  }

  &__9 {
    top: 55px;
    right: 600px;
    animation: mapPulse 3.0s infinite;
  }

  &__10 {
    top: 260px;
    right: 580px;
    animation: mapPulse 3.0s infinite;
  }

  &__11 {
    bottom: 300px;
    right: 210px;
    animation: mapPulse 3.0s infinite;
  }

  &__12 {
    top: 200px;
    right: 150px;
    animation: mapPulse 3.0s infinite;
  }

  &__13 {
    top: 55px;
    right: 140px;
    animation: mapPulse 3.0s infinite;
  }

  &__14 {
    bottom: 31px;
    right: 180px;
    animation: mapPulse 3.0s infinite;
  }
}
