@import "bower_components/Swiper/dist/css/swiper";
@import "variables";
@import "bootstrap";
@import "navbar";
@import "slidebar";
@import "jquery-steps";
@import "buttons";
@import "utilities-spacing";
@import "footer";
@import "components/loaders";
@media (max-width: $screen-xs-max) {
  .h1,
  h1 {
    font-size: 75px;
  }
  .h2,
  h2 {
    font-size: 32px;
  }
  .h3,
  h3 {
    font-size: 18px;
  }
  .h4,
  h4 {
    font-size: 16px;
  }
  .h5,
  h5 {
    font-size: 14px;
  }
  .h6,
  h6 {
    font-size: 12px;
  }
}

/// some more description
/// @param {String} $foo [bar] - Baz.
@function baz($foo) {}

/// what the fuck
@mixin vertical-align() {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.support-companies {
  height: 170px;
  background-color: white;
  width: 100%;
}

.supp-text {
  text-align: center;
  display: block;
  padding-top: 0;
  color: rgba(white, .70);
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 30px;
  @media (max-width: 1280px) {
    padding-top: 20px;
    font-size: 14px;
  }
}

.supp-txt {
  text-align: center;
  display: block;
  font-size: 18px !important;
  padding-top: 0;
  color: rgba(#2A3E61, .70);
  background-color: white;
  margin-bottom: 0;
  padding-top: 30px;
  @media (max-width: 1280px) {
    padding-top: 20px;
    font-size: 18px;
  }
}

.featin {
  border-bottom: 1px solid gainsboro;
}

.featin-p-b {
  padding-bottom: 60px !important;
}

.big-companies {
  margin: auto;
  padding: 20px 0;
  text-align: center;
}

.navbar {
  transition: padding 0.3s;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.bg-primary-dark {
  color: #fff;
  background-color: $brand-primary-dark;
}

.form-control {
  background-color: #fff;
  border: 2px solid #e0e6e8;
  border-radius: 2px !important;
  height: 50px;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  transition: border .15s;
  width: 100%;
  font-size: 16px;
  color: #2A3E61;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: $screen-xs-max) {
    // padding: 20px 30px;
  }
  &:focus {
    border: 2px solid $brand-primary;
    text-decoration: none;
    outline: none;
  }
}

.form-control::-webkit-input-placeholder {
  color: rgba(#2A3E61, 0.5);
  -webkit-font-smoothing: antialiased;
}

.float-right {
  float: right;
}

.jumbotron-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 0;
  .no-cssanimations & {
    opacity: 1;
  }
  &-1 {
    background-image: url("../images/jumbotron-bg-1.jpg");
  }
  &-2 {
    background-image: url("../images/jumbotron-bg-2.jpg");
  }
  &-3 {
    background-image: url("../images/jumbotron-bg-3.jpg");
  }
  &-4 {
    background-image: url("../images/shutterstock_687092968.jpg");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  &-5 {
    background-image: url("../images/jumbotron-bg-5.jpg");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  &-6 {
    background: linear-gradient(141deg, #0094E6, $brand-primary);
    height: 720px;
  }
  &-7 {
    background-image: url("../images/jumbotron-bg-7.jpg");
  }
  &-8 {
    background-image: url("../images/chefwithipad.jpg");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  &-9 {
    background-image: url("../images/teamcover.png");
  }
  &-10 {
    background: linear-gradient(rgba(5, 32, 58, 0.45), rgba(6, 25, 41, 0.45)), url("../images/team.jpg"), no-repeat;
    background-size: cover;
  }
  &-11 {
    background: linear-gradient(rgba(5, 32, 58, 0.45), rgba(6, 25, 41, 0.45)), url("../images/faq-photo.png"), no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
  &-12 {
    background: url("../images/casestudies-bg.jpg"), no-repeat;
    background-size: cover;
  }
  &-13 {
    background-image: url("../images/media-bg-2.jpg");
  }
  &-14 {
    background-image: url("../images/freechecklist/free-checklists-hero.jpg");
  }
}

.mediaphoto {
  height: 420px;
  .headline-title {
    color: white;
    z-index: 99999;
    display: flex;
    justify-content: center;
    margin: 180px auto 20px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.8px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  }
}

.tools-page {
  background-color: azure;
  .details-content {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    .detail-banner {
      margin-bottom: 90px;
      margin-top: 10px;
    }
  }
  .headtext {
    line-height: 1.5;
    text-shadow: none;
    letter-spacing: 0.8px;
    font-size: 20px;
    text-transform: none;
    font-weight: 300;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 25px;
      font-size: 20px;
      text-align: center;
    }
    &--mt-20 {
      margin-top: 20px;
      font-size: 22px;
      line-height: normal;
      color: #4F7293;
      text-align: start;
      @media (max-width: $screen-xs-max) {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}

.iphone-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 0;
  .no-cssanimations & {
    opacity: 1;
  }
  &-1 {
    background-image: url("../images/jumbotron-bg-1.jpg");
  }
  &-2 {
    background-image: url("../images/jumbotron-bg-2.jpg");
  }
  &-3 {
    background-image: url("../images/jumbotron-bg-3.jpg");
  }
}

.jumbotron-section {
  position: relative;
  background-color: #444;
  color: #fff;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.jumbotron-swiper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  &>.swiper-pagination {
    bottom: 40px;
  }
}

.iphone-swiper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 270px;
  height: 570px;
  padding-top: 103px;
  margin-top: -52px;
  margin-left: 22px;
  img {
    width: 270px;
    height: 470px;
  }
}

.iphone-wrapper {
  width: 300px;
  height: 580px;
  background-image: url('../images/mobile-container.png');
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.jumbotron-title {
  margin-bottom: 30px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }
}

.jumbotron-headline {
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.80);
  letter-spacing: 1px;
  font-size: 48px;
  font-weight: 500;
  text-transform: none;
  @media (max-width: $screen-sm-max) {
    font-size: 32px;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 36px;
  }
}

.full-width {
  width: 100% !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.form-control-header {
  background-color: rgba(#fff, 0.5);
  border: none;
  color: white;
  -webkit-font-smoothing: antialiased;
}

.form-control-header::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
}

.form-control-header:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.form-control-header::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.jumbotron-text {
  line-height: 1.5;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.80);
  letter-spacing: 0.8px;
  font-size: 21px;
  font-weight: 300;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 25px;
    font-size: 20px;
  }
  @media (min-width: $screen-sm-min) {
    margin-bottom: 5px;
    font-size: 21px;
  }
  @media (min-width: $screen-md-min) {
    margin-bottom: 60px;
  }
  @media (min-width: $screen-lg-min) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  &--no-mb {
    margin-bottom: 0;
  }
  &--mt-20 {
    margin-top: 10px;
  }
}

.pad-t-5 {
  padding-top: 5px;
}

.nav-btns {
  max-width: 50px;
  width: 100%;
  opacity: 0.6;
  &:focus {
    outline: none;
    outline-style: none;
  }
}

.testimonial-prev {
  outline: none;
}

.testimonial-next {
  outline: none;
}

.jumbotron-button {
  position: relative;
  display: inline-block;
  background-color: #1BB4FF;
  padding: 20px 40px 20px;
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  transition: all .22s ease-in-out;
  text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50);
  width: auto;
  line-height: 32px;
  letter-spacing: 0.5px;
  @media (max-width: $screen-xs-max) {
    padding: 20px;
  }
  &:hover {
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
  }
  &:focus {
    color: white;
    text-decoration: none;
  }
}

.mw-companies-text {
  line-height: 1.5;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.80);
  max-width: 1100px;
  margin: 0 auto;
  font-size: 18px;
}

.mw-companies-textbox {
  text-align: center;
  line-height: 1.5;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.80);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  background-color: rgba(0, 0, 0, 0.3);
  &-link {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  @media (min-width: $screen-md-min) {
    padding: 20px 150px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 20px 150px;
  }
}

.mw-companies {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  &-image {
    opacity: 1;
    z-index: auto;
    @media (max-width: 1564px) {
      max-width: 90%;
      padding: 0 10px;
    }
    @media (max-width: 1280px) {
      max-width: 92%;
      padding: 0;
    }
  }
  &-link {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  @media (min-width: $screen-md-min) {
    padding: 5px 100px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 5px 150px;
  }
}

.header-support {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  background-color: white;
  width: 100vw;
  &-image {
    opacity: 1;
    z-index: auto;
    @media (max-width: 1564px) {
      max-width: 90%;
      padding: 0 10px;
    }
  }
  &-link {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  @media (min-width: $screen-md-min) {
    padding: 25px 130px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 0 120px 10px;
  }
}

.exciting-features {
  background: #0b0b21;
  background-size: cover;
  color: #fff;
  @media (max-width: 769px) {
    text-align: center;
  }
  @media (max-width: 425px) {
    display: none;
  }
}

.features-button {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 40px;
  background-color: #1BB4FF;
  padding: 20px 40px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  transition: all .22s ease-in-out;
  text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50);
  width: auto;
  line-height: 32px;
  letter-spacing: 0.5px;
  &:hover {
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    text-decoration: none;
  }
}

.feat-title {
  font-weight: 500;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
}

.playbtn {
  width: 38px;
  height: 38px;
  margin-top: 0;
  margin-right: 10px;
  display: inline-block;
}

.center-btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.txt-btn {
  font-weight: 500;
  margin-top: 16px;
  display: inline-block;
}

.exciting-features-list {
  text-align: center;
  line-height: 90px;
  padding-top: 0;
  &--1 {
    top: 60px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  .media-overflow {
    overflow: visible !important;
  }
}

.boredom-img {
  position: absolute;
  width: 458px;
  right: 0;
  top: -43px;
  @media (max-width: $screen-xs-max) {
    position: relative;
    width: 300px;
    left: -160px;
    top: 20px;
  }
}

.m-top-200 {
  margin-top: 200px;
  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
  @media (max-width: $screen-sm-max) {
    margin-top: 0;
  }
}

.feat-pad-btm {
  padding-bottom: 20px !important;
}

video.exciting-object {
  border: none;
  border-radius: 0;
  cursor: pointer;
  &:hover {
    -webkit-animation: pulsate 4s ease-out;
    -webkit-animation-iteration-count: infinite;
  }
}

@-webkit-keyframes pulsate {
  0% {
    border-color: rgba(white, 0.3);
  }
  50% {
    border-color: rgba(white, 0.7);
  }
  100% {
    border-color: rgba(white, 0.3);
  }
}

.features-cont {
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.exciting-features-image {
  // @include img-retina( '../images/mobile-container-2.png', '../images/mobile-container-2.png', 205px, 364px);
  background: transparent no-repeat center;
  width: 205px;
  height: 364px;
  position: relative;
  border: 0 !important;
  z-index: 999; //border: 10px solid rgba(255, 255, 255, 0.3);
  @media (max-width: $screen-xs-max) {
    box-sizing: content-box;
    border-radius: 3px;
    margin: 0 auto 60px;
  }
  @media (min-width: $screen-sm-min) {
    //  @include img-retina( '../images/mobile-container-2.png', '../images/mobile-container-2.png', 683px, 714px);
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 620px;
    &:after {
      content: "";
      display: block; // @include img-retina('../images/exciting-features-screen.png', '../images/exciting-features-screen@2x.png', 205px, 364px);
      background-repeat: no-repeat;
      background-position: top left;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 57px auto 0 297px;
    }
    #main-video-youtube,
    video {
      position: relative;
      display: flex;
      justify-content: center;
      margin: auto;
      top: 125px;
    }
  }
  @media (min-width: $screen-md-min) {
    background-position: center;
    &:after {
      background-position: top;
      margin: 57px 79px 0 auto;
    }
    #main-video-youtube,
    video {
      position: relative;
      display: flex;
      justify-content: center;
      margin: auto;
      top: 125px;
    }
  }
}

#demo-screen {
  transition: 1s -webkit-filter linear;
  cursor: pointer;
  -webkit-animation: fadein 1s;
}

#demo-newsfeed {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 400px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      @media (min-width: $screen-sm-min) {
        transform: translate(0%, -50%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(0%, -50%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(0%, -50%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/newsfeed@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

#demo-smartcheck {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 600px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      @media (min-width: $screen-sm-min) {
        transform: translate(0%, -50%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(0%, -50%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(0%, -50%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/smartcheck@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

#demo-customizable {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 600px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      @media (min-width: $screen-sm-min) {
        transform: translate(0%, -50%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(0%, -50%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(0%, -50%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/custom@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

#demo-efficient {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 600px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(-100%, 0%);
      @media (min-width: $screen-sm-min) {
        transform: translate(-100%, 0%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(-100%, 0%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(-100%, 0%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/mobile@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

#demo-offline {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 600px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(-100%, 0%);
      @media (min-width: $screen-sm-min) {
        transform: translate(-100%, 0%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(-100%, 0%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(-100%, 0%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/offline@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

#demo-agnostic {
  cursor: pointer;
  -webkit-animation: fadein 1s;
  transition: all .22s ease-in-out;
  &:hover,
  &.hover {
    color: #fff;
    transform: translateY(-4px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    filter: brightness(110%);
    &:before {
      content: '';
      position: absolute;
      border: 1px solid white;
      width: 600px;
      top: 47px;
      left: 345px;
      right: 50%;
      left: 50%;
      transform: translate(-100%, 0%);
      @media (min-width: $screen-sm-min) {
        transform: translate(-100%, 0%);
        width: 400px;
      }
      @media (min-width: 1280px) {
        transform: translate(-100%, 0%);
        width: 500px;
      }
      @media (min-width: 1440px) {
        transform: translate(-100%, 0%);
        width: 535px;
      }
    }
    &:after {
      content: '';
      background-image: url(../images/agnostic@2x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      background-color: #19283F;
      position: absolute;
      top: 27%;
      right: 50%;
      left: 50%;
      bottom: 0;
      width: 80px;
      height: 80px;
      border: 2px solid white;
      border-radius: 100px;
      transition: all .22s ease-in-out;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.feature-image {
  width: 106%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  top: 55px;
  left: -8px;
}

.exciting-features-item {
  .media-heading {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
  }
  .ico {
    width: 50px;
    z-index: 999;
  }
}

.how-to-coinspect {
  position: relative;
  z-index: $zindex-dropdown;
}

.how-to-coinspect-container {
  background-color: #fff;
  color: #4a4a4a;
  padding: 40px 25px;
}

@media (min-width: $screen-sm-min) {
  .how-to-coinspect {
    background: #0b0b21 url('../images/bg-pattern-alt.png') repeat center center;
    color: #fff;
  }
  .how-to-coinspect-container {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.30);
    border-radius: $border-radius-large;
    margin: -60px auto 60px;
    padding: 100px 75px;
    p {
      font-size: 16px;
    }
  }
}

.tools-support {
  position: relative;
  background-color: white !important;
  z-index: $zindex-dropdown;
  .headtext {
    line-height: 1.5;
    text-shadow: none;
    letter-spacing: 0.8px;
    font-size: 16px;
    text-transform: none;
    font-weight: 300;
    color: #4F7293;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 25px;
      font-size: 20px;
      text-align: center;
    }
    &--mt-20 {
      margin-top: 20px;
      font-size: 18px;
      line-height: normal;
      color: #4F7293;
      text-align: start;
      @media (max-width: $screen-xs-max) {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .tools-btn {
    width: 100%;
    text-align: center;
    padding: 20px 18px;
  }
  .checktext {
    margin-left: 40px;
    display: inline-block;
    width: 60%;
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
      text-align: center;
    }
  }
  .tools-image {
    float: left;
    display: block;
    position: relative;
    left: 80px;
    top: 50px;
    padding-bottom: 80px;
  }
}

.tools-title {
  padding-top: 10px;
}

.tools-container {
  background-color: transparent;
  color: #4a4a4a;
  padding: 40px 25px;
  @media (max-width: $screen-xs-max) {
    padding: 40px 25px 0;
  }
}

.tac-xs {
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.btn-m-b {
  @media (max-width: $screen-xs-max) {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

.tools-ico {
  position: relative;
  padding-bottom: 10px;
  vertical-align: baseline;
  width: 80px;
  display: inline-block;
  @media (max-width: $screen-xs-max) {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 auto 20px;
  }
  &--firstwo {
    top: 28px;
  }
  &--last {
    @media (max-width: $screen-xs-max) {
      top: 28px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .tools-support {
    background: white;
    color: #fff;
  }
  .tools-container {
    box-shadow: none;
    padding: 20px 5px 100px;
    p {
      font-size: 16px;
    }
  }
}

.pricing-container--sm {
  @media (max-width: 425px) {
    height: 850px;
  }
}

.z-index-9999 {
  z-index: 9999;
}

.t-a-c {
  text-align: center !important;
}

.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 3rem;
  &-prev {}
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    padding-bottom: 30px;
    border: 1px solid;
    margin-left: 90px;
    text-align: start;
    padding-left: 30px;
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }
    li {
      font-size: 18px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      line-height: 36px;
    }
  }
  .light-blue {
    border: 1px solid #1AB5FF;
  }
  @media (min-width: $screen-sm-min) {
    // padding: 12rem 0;
  }
  &-section {
    text-align: center;
    background-color: #070716;
    .pricing-header {
      margin-top: 0;
      padding-top: 40px;
    }
    .z-index-999 {
      z-index: 999;
    }
    .header-price {
      h2 {
        font-size: 32px;
        margin-top: 0;
        color: #070716;
      }
      h3 {
        font-size: 18px;
        margin-top: 0;
        color: rgba(29, 52, 90, 0.6);
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
      }
    }
    .footer-price {
      padding-top: 40px;
      h2 {
        font-size: 32px;
        margin-top: 0;
        color: #070716;
      }
    }
    .prices-container {
      display: flex;
      justify-content: center;
      margin: 20px 0;
      @media (max-width: $screen-xs-max) {
        display: block;
      }
      .price-h3 {
        text-align: left;
      }
    }
    .price-buttons {
      display: block;
      .prc-btn {
        width: auto;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      .prc-btn-pro {
        width: 100%;
        margin-top: 80px;
      }
    }
    .content-price {
      padding-top: 40px;
      border-top: 1px solid gainsboro;
      margin-top: 20px;
      h3 {
        font-size: 16px;
        ;
        margin-top: 0;
        font-weight: 400;
        line-height: 28px;
        color: rgba(29, 52, 90, 0.6);
        -webkit-font-smoothing: antialiased;
      }
    }
    .free-price {
      background-color: white;
      width: 100%;
      max-width: 270px;
      min-width: 260px;
      min-height: 428px;
      margin-top: 40px;
      text-align: center;
      display: block;
      justify-content: center;
      margin: 70px auto 0;
      border: 1px solid $brand-primary;
      padding: 40px;
      transition: all 0.22s ease-in-out;
      &:hover {
        transition: all 0.22s ease-in-out;
        transform: translateY(-5px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
      }
    }
    .pro-price {
      background-color: white;
      width: 100%;
      max-width: 270px;
      min-width: 260px;
      min-height: 492px;
      margin-top: 40px;
      text-align: center;
      display: block;
      justify-content: center;
      margin: 40px auto;
      border: 1px solid $brand-primary;
      padding: 40px;
      border-top: 20px $brand-primary solid;
      transition: all 0.22s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: white;
        width: 100%;
        max-width: 270px;
        min-width: 260px;
        min-height: 492px;
        margin-top: 40px;
        text-align: center;
        display: block;
        justify-content: center;
        margin: 40px auto;
        border: 1px solid $brand-primary;
        padding: 40px;
        border-top: 20px $brand-primary solid;
        transition: all 0.22s ease-in-out;
        transform: translateY(-5px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
      }
    }
    .corp-price {
      background-color: white;
      width: 100%;
      max-width: 270px;
      min-width: 260px;
      min-height: 428px;
      margin-top: 40px;
      text-align: center;
      display: block;
      justify-content: center;
      margin: 40px auto;
      padding: 40px;
      margin: 70px auto 0;
      transition: all 0.22s ease-in-out;
      border: 1px solid $brand-primary;
      &:hover {
        transition: all 0.22s ease-in-out;
        transform: translateY(-5px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
      }
    }
    &>section {
      max-width: 800px;
      margin: 0 auto 30px;
      p {
        font-size: 1.1em;
        padding: 10px;
        @media (min-width: $screen-sm-min) {
          font-size: 1.1em;
        }
        @media (min-width: $screen-md-min) {
          font-size: 1.2em;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 1.3em;
        }
      }
    }
  }
  &-bg {
    background: transparent url('../images/pricing-table-bg@2x.png') no-repeat center center;
    background-size: contain;
    width: 80%;
  }
  &-item-full {
    position: relative;
    display: flex;
    min-width: 1200px;
    min-height: 600px;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    clear: none;
    flex: 0 1 330px;
    margin: 1em; // padding: 25px 30px 60px;
    color: #070716;
    border-top: 3px solid #eef0f3;
    transition: border-color 0.3s;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(28, 51, 90, 0.47);
    border-radius: $border-radius-large;
    padding-bottom: 40px;
    @media (max-width: $screen-xs-max) {
      box-shadow: none;
      border-top: none;
    }
    .h3-header {
      @media (max-width: $screen-xs-max) {
        padding: 40px 0;
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .h3-labels {
      @media (max-width: $screen-xs-max) {
        font-size: 16px;
        padding-top: 20px;
      }
    }
    .reduce-height {
      height: 600px;
    }
    .pricing-slide {
      width: 1200px !important;
      .btn-actn {
        background: #1AB5FF;
        margin: auto;
        width: auto;
        font-size: 18px;
        color: white;
        padding: 16px 50px;
        border-radius: 2px;
        margin-top: 28px;
        margin-bottom: 40px;
        line-height: 56px;
      }
      @media (max-width: $screen-xs-max) {
        width: 200px;
      }
    }
    .pricing-tbl {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .pricing-tbl-0 {
      width: 100%;
      padding-top: 40px;
    }
    .pricing-table {
      width: 1000px;
      &-resto-1 {
        display: inline-block;
        width: 40%;
        position: relative;
        left: -60px;
        margin-right: 60px;
        @media (max-width: $screen-xs-max) {
          display: block;
          margin-right: 0;
          left: 0;
          width: 30%;
          margin: auto;
          padding-bottom: 40px;
        }
        .in-store-item {
          width: 100%;
          padding: 0 20px 0 0;
          @media (max-width: $screen-xs-max) {
            padding: 0;
          }
        }
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    h3 {
      font-size: 20px;
      padding-top: 30px;
      font-family: $font-family-sans-serif;
      -webkit-font-smoothing: antialiased;
      color: $font-color-pricing;
      @media (max-width: $screen-xs-max) {
        font-size: 22px;
        padding-bottom: 20px;
        margin: 0;
      }
    }
    button {
      background: none;
      border: none;
      text-align: start;
      display: inline-block;
      padding: 10px 30px;
      width: 1200px;
      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
      .img-btn {
        display: inline-block;
      }
      .h3-btn {
        display: inline-block;
        padding-top: 0;
        padding-left: 20px;
        position: relative;
        bottom: 2px;
        @media (max-width: $screen-xs-max) {
          font-size: 20px;
        }
      }
      .h3-btn2 {
        display: inline-block;
        padding-top: 0;
        padding-left: 20px;
        position: relative;
        bottom: -2px;
        @media (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }
    }
    .manu-btn {
      @media (max-width: 475px) {
        width: 350px;
      }
    }
    .manufacture {
      @media (max-width: $screen-xs-max) {
        position: absolute;
        z-index: 99999;
      }
    }
    .image-container {
      &-1 {
        width: 520px;
        min-height: 400px;
        display: inline-block;
        margin-top: 40px;
        cursor: pointer;
        position: relative;
        left: -30px;
        border-right: 1px solid gainsboro;
        @media (max-width: $screen-xs-max) {
          display: inline;
          left: 0;
          border-right: none;
        }
        .img-resto {
          background: transparent url('../images/pricing/p-resto2.png') no-repeat center center;
          background-size: contain;
          width: 305px;
          height: 305px;
          margin: auto;
          @media (max-width: $screen-xs-max) {
            background: transparent url('../images/pricing/p-resto.png') no-repeat center center;
            background-size: contain;
            width: 250px;
            height: 250px;
            margin: auto;
          }
          &:hover {
            background: transparent url('../images/pricing/p-resto.png') no-repeat center center;
            background-size: contain;
            width: 305px;
            height: 305px;
            margin: auto;
            transition: all ease-in-out 0.2s;
          }
        }
      }
      &-2 {
        display: inline-block;
        cursor: pointer;
        @media (max-width: $screen-xs-max) {
          display: inline;
          left: 0;
        }
        .pricing-labels {
          position: relative;
          left: -20px;
          @media (max-width: $screen-xs-max) {
            left: 0;
            bottom: 20px;
          }
        }
        .img-others {
          background: transparent url('../images/pricing/p-others2.png') no-repeat center center;
          background-size: contain;
          width: 605px;
          height: 305px;
          margin: auto;
          &:hover {
            background: transparent url('../images/pricing/p-others.png') no-repeat center center;
            background-size: contain;
            width: 605px;
            height: 305px;
            margin: auto;
            transition: all ease-in-out 0.2s;
          }
          @media (max-width: $screen-xs-max) {
            width: 340px;
            height: 150px;
            background: transparent url('../images/pricing/p-others.png') no-repeat center center;
            background-size: contain;
            margin: auto;
          }
        }
        h3 {
          display: inline-block;
          padding: 0 14px;
          @media (max-width: $screen-xs-max) {
            padding: 0 6px;
            font-size: 12px;
          }
        }
      }
    }
  }
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    flex: 0 1 330px;
    margin: 1em; // padding: 25px 30px 60px;
    color: #070716;
    border-top: 3px solid #eef0f3;
    transition: border-color 0.3s;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(28, 51, 90, 0.47);
    border-radius: $border-radius-large;
    &--enterprise {
      border: 6px solid rgba(26, 181, 255, 0.5);
      border-radius: $border-radius-large;
    }
  }
  &-title {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    color: white;
    letter-spacing: 1.33px;
    line-height: 1.2;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
  &-title+&-hr {
    border-width: 2px;
  }
  &-text {
    line-height: 1.8;
    padding: 40px 10px 0;
    font-size: 18px;
    color: #4F7293;
    font-weight: 500;
  }
  &-hr {
    width: 100%;
  }
  &--solo &-hr {
    border-color: #979797;
  }
  &--pro &-hr {
    border-color: $brand-primary;
  }
  &--enterprise &-hr {
    border-color: $brand-warning;
  }
  &-price {
    margin-bottom: 24px;
    line-height: 1.2;
    color: #070716; // letter-spacing: 1.33px;
    &--currency {
      font-weight: 300;
      font-size: 24px;
    }
    &--value {
      font-size: 52px;
      font-weight: 500;
    }
    &--contact {
      font-size: 30px;
      line-height: 82px;
    }
    &--period {
      display: block;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.mw-team {
  &-bg {
    // background: transparent url('../images/team-bg@2x.png') no-repeat center center;
    background-size: contain;
  }
}

.photo-team {
  margin: 0;
}

.mw-teammate {
  text-align: center;
  max-width: 220px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .22s ease-in-out;
  &:hover {
    filter: brightness(110%);
  }
  .team-details {
    background-color: white;
    margin-right: 0;
    margin-left: 0;
    position: relative;
    height: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: rgba(43, 80, 121, 1);
      -webkit-font-smoothing: antialiased;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      color: rgba(43, 80, 121, 1);
      -webkit-font-smoothing: antialiased;
    }
    h5 {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      padding-left: 20px;
      padding-right: 10px;
      line-height: normal;
      color: rgba(43, 80, 121, 0.7);
      -webkit-font-smoothing: antialiased;
    }
  }
  &-img-wrap {
    width: 100%;
    height: 210px;
    /*padding-top: 100%;*/
    position: relative;
    overflow: hidden;
  }
  &-img-wrap &-bio {
    display: block;
  }
  &-bio {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /*background-color: #0fc8ff;*/
    color: #fff;
    font-size: 15px;
    text-align: left;
    padding: 10px;
    line-height: 1.4;
    font-weight: 300;
  }
  &-bio-text {
    @include vertical-align();
    padding-right: 1px;
    padding-left: 30px;
    padding-top: 14px;
  }
  &-img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    &--border-bottom {
      border-bottom: 10px solid transparentize($brand-primary, 0);
    }
    &--alt {
      @include img-responsive();
      margin-right: auto;
      margin-left: auto;
    }
  }
  &-name {
    font-size: 21px;
    color: $brand-primary;
    font-weight: bold;
    /*background-color: #070716;*/
  }
  &-position {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    /*background-color: #070716;*/
  }
  &-advisors-name {
    font-size: 21px;
    color: $brand-primary;
    font-weight: bold;
  }
  &-advisors-position {
    font-size: 16px;
    color: #070716;
    font-weight: 300;
  }
}

.mw-join {
  background: transparent url('../images/join-bg.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  min-height: 94vh;
  &-text {
    line-height: 1.8;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  }
}

.mw-careers-text {
  line-height: 1.5;
  padding: 0 25px;
  font-size: 14px;
}

.header-headline-title {
  color: #fff;
  text-align: left;
  font-size: 46px;
  font-weight: 600;
  margin-top: 0;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 1280px) {
    font-size: 34px;
    text-align: left;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 30px;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    padding-top: 50px;
  } // &:after {
  //   content: "";
  //   display: block;
  //   border-bottom: 3px solid #fff;
  //   width: 140px;
  //   margin: 20px auto 0;
  // }
  &-primary {
    color: $brand-primary;
    padding-bottom: 15px; // &:after {
    //   border-color: $brand-primary;
    // }
  }
  &-features-title {
    padding-bottom: 60px !important;
  }
  @media (min-width: $screen-sm-min) {
    &-left {
      text-align: left;
      &:after {
        margin-left: 0;
        margin-right: auto;
      }
    }
    &-right {
      text-align: right;
      :after {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

.header-content {
  @media (max-width: 1280px) {
    font-size: 20px;
    text-align: left;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
}

.section-title {
  color: #fff;
  text-align: left;
  font-size: 60px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  @media (max-width: $screen-xs-max) {
    font-size: 30px;
    text-align: center;
  } // &:after {
  //   content: "";
  //   display: block;
  //   border-bottom: 3px solid #fff;
  //   width: 140px;
  //   margin: 20px auto 0;
  // }
  &-primary {
    color: $brand-primary;
    padding-bottom: 15px; // &:after {
    //   border-color: $brand-primary;
    // }
  }
  &-features-title {
    padding-bottom: 60px !important;
  }
  @media (min-width: $screen-sm-min) {
    &-left {
      text-align: left;
      &:after {
        margin-left: 0;
        margin-right: auto;
      }
    }
    &-right {
      text-align: right;
      :after {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

.sect2-title {
  font-size: 55px;
  @media (max-width: $screen-xs-max) {
    font-size: 32px
  }
}

.sect-subtitle {
  font-size: 18px;
  font-weight: 400;
  color: white;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.case-link {
  color: rgba(28, 51, 90, 0.7);
  -webkit-font-smoothing: antialiased;
  text-align: center;
  padding-bottom: 40px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 400;
  padding-top: 20px;
}

.demo-row {
  padding-bottom: 50px !important;
}

.modal-free {
  background-color: rgba(#000, 0.7) !important;
}

.free-mode-dialog {
  width: 800px;
  margin: 30px auto;
  background-color: #fff;
  border-radius: 4px;
  @media (max-width: 1280px) {
    width: 600px;
  }
}

.top-bg {
  background-image: url('../images/head-bg-1.jpg');
  background-position: center center;
  background-size: cover;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  width: 800px;
  height: 306px;
  @media (max-width: 1280px) {
    width: 600px;
    height: 206px;
  }
}

.free-mode-title {
  color: #2B5079;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 24px;
  }
}

.free-mode-desc {
  color: #2B5079;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2px;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 14px;
  }
}

.free-mode-content {
  border: none;
  width: 100%;
  position: relative !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 6px;
  outline: 0;
  margin: 20% auto !important;
  padding: 50px 100px !important;
}

.free-mode-header {
  padding: 40px 10px 0;
  @media (max-width: 1280px) {
    padding: 2px 10px 0;
  }
}

.x-icon {
  right: 30px;
  position: absolute;
  top: 30px;
  color: white;
  float: right;
  width: 30px;
  opacity: 0.4;
  @media (max-width: 1280px) {
    width: 20px;
  }
}

.form-or {
  padding: 20px 0;
  text-align: center;
  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    display: none;
  }
  ul.hs-error-msgs.inputs-list li label {
    padding-top: 10px;
    text-align: center;
  }
}

.sm-center {
  @media (max-width: $screen-sm-max) {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 0;
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: absolute;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  left: 50%;
  top: 50%;
  bottom: 50%;
  right: 50%;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  background-color: white;
  transition: all .22s ease-in-out;
  transform: translate(-50%, -50%);
}

// .modal-dialog {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
// }
.modal-cases {
  margin: 5% auto !important;
}

.modal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  background-clip: padding-box;
  padding: 0 100px;
  border: none;
  width: 100%;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  @media (max-width: $screen-xs-max) {
    padding: 0 20px;
  }
  @media (max-width: 1680px) {
    margin: 5% auto;
  }
}

@-webkit-keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

body.modal-open {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;
}

.contact-modal {
  position: fixed;
  display: none;
  width: 100%;
  top: 0px;
  left: 0px;
  height: -webkit-fill-available;
  z-index: 2147483647;
  background: 0% 0% / cover white;
  overflow: hidden;
}

.demo-testimonial {
  padding-left: 60px; // border-left: 1px solid white;
}

.demo-testi-details {
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
}

.demo-testi-logo img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.demo-testi-story {
  color: #0b0b21;
  padding-left: 30px;
  .testi-text {
    font-size: 18px;
    font-style: italic;
    color: rgba(#0b0b21, 0.5);
    text-shadow: none;
    line-height: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  }
  .footer-details {
    display: flex;
    color: rgba(#0b0b21, 0.5);
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  .footer-contact p {
    margin-bottom: 0;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    color: rgba(#0b0b21, 0.5);
  }
  .footer-contact p:first-child {
    font-weight: 600;
  }
}

.how-to-coinspect-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  >li {
    color: rgba(32, 80, 129, 0.5);
    cursor: pointer;
    padding: 15px 0;
    &:before {
      content: "●";
      font-size: 50px;
      color: inherit;
      padding-right: 15px;
      vertical-align: text-top;
      line-height: 19px;
    }
    &.active {
      color: $brand-primary;
    }
    >a {
      color: inherit;
    }
  }
  @media (max-width: $screen-xs-max) {
    text-align: center;
    padding-top: 20px;
    >li {
      display: inline-block;
      padding: 0;
      &:before {
        font-size: 40px;
        padding: 0 5px;
        line-height: 0;
      }
      span {
        display: none;
      }
    }
  }
}

.col-sm-exp {
  right: 96%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.p-b-n {
  @media (max-width: $screen-xs-max) {
    padding-bottom: 0 !important;
  }
}

.p-t-n {
  @media (max-width: $screen-xs-max) {
    padding-top: 0 !important;
  }
}

.mw-feature {
  margin-top: 60px;
  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
  &-img {
    @include img-responsive();
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $screen-xs-max) {
      &--search {
        margin-top: -11rem;
      }
    }
    @media (min-width: $screen-sm-min) {
      &--start {
        margin-left: 0;
      }
      &--end {
        margin-right: 0;
      }
      &--negative-margin {
        margin-top: 80px;
      }
      &--negative-margin-lg {
        margin-top: 100px;
      }
      &--transform {
        transform-origin: 0 100%;
        transform: rotate(-6deg);
      }
      &--shadow {
        position: absolute;
        bottom: -12.8em;
        left: 12.8em;
        height: 32em;
        transform-origin: 0 100%;
        transform: rotate(-3deg);
        opacity: 0.4;
        margin-left: -39px !important;
      }
    }
  }
  /*.mw-feature-photos {
  &-img {
    @include img-responsive();
    height: auto;
    transform-origin: 0 100%;
    transform: rotate(-15deg);
    @media (max-width: $screen-xs-max) {
      &--search {
        margin-top: -11rem;
      }
    }
    @media (min-width: $screen-sm-min) {
      &--start {
        margin-left: 0;
      }
      &--end {
        margin-right: 0;
      }
      &--negative-margin {
        margin-top: 80px;
      }
      &--negative-margin-lg {
        margin-top: 110px;
      }
    }
  }
}*/
  @media (max-width: $screen-xs-max) {
    &--xs-padding-top {
      padding-top: 6rem;
      &-sm {
        padding-top: 4rem;
      }
      &-lg {
        padding-top: 8rem;
      }
    }
    &--xs-padding-bottom {
      padding-bottom: 6rem;
      &-sm {
        padding-bottom: 4rem;
      }
      &-lg {
        padding-bottom: 8rem;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    &--sm-padding-top {
      padding-top: 6rem;
      &-sm {
        padding-top: 4rem;
      }
      &-lg {
        padding-top: 8rem;
      }
    }
    &--sm-padding-bottom {
      padding-bottom: 6rem;
      &-sm {
        padding-bottom: 4rem;
      }
      &-lg {
        padding-bottom: 8rem;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .mw-feature {
    text-align: center;
  }
}

p.mw-featurestag {
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.7px;
  font-weight: 300;
}

h3.features-p {
  color: white;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.7px;
  font-weight: 300;
  @media (max-width: $screen-sm-min) {
    font-size: 14px;
    line-height: 18px;
  }
}

.features-title {
  color: white;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.9px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  @media (max-width: $screen-sm-min) {
    font-size: 20px;
    line-height: 24px;
  }
}

.mw-positions {
  position: relative;
  >.content {
    transition: min-height 0.3s;
    transform: translate3d(0, 0, 0);
    will-change: min-height;
  }
}

.mw-position {
  &+& {
    margin-top: 25px;
  }
  &-screen {
    display: none;
    .text-left {
      text-align: left !important;
    }
  }
  &-card {
    background-color: $brand-primary-dark;
    border-radius: $border-radius-small;
    padding: 20px 30px;
    text-align: left;
    &.viewer {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

.mw-current-step {
  // &-0 > .content,
  // &-1 > .content {
  //   min-height: 28em;
  // }
  // &-2 > .content {
  //   min-height: 50em;
  // }
  // &-3 > .content {
  //   min-height: 68em;
  // }
  // &-4 > .content {
  //   min-height: 81em;
  // }
}

.apply-now {
  margin-top: 30px;
}

@media (min-width: $screen-sm-min) {
  .mw-current-step {
    // &-0 > .content,
    // &-1 > .content {
    //   min-height: 28em;
    // }
    // &-2 > .content {
    //   min-height: 29em;
    // }
    // &-3 > .content {
    //   min-height: 47em;
    // }
    // &-4 > .content {
    //   min-height: 51em;
    // }
  }
}

.swiper-wrappers {
  &:focus {
    outline: none;
  }
}

.swiper-pagination-bullet {
  background: #ccc;
  opacity: 1;
  border: 1px solid #ccc;
  width: 15px;
  height: 15px;
}

.swiper-pagination-bullet-active {
  background: $brand-primary;
  border-color: $brand-primary;
}

@media (min-width: $screen-sm-min) {
  .swiper-pagination-bullet {
    background-color: transparent;
    border-color: #fff;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff;
    border-color: #fff;
  }
}

.section-bg-pattern {
  position: relative;
  color: #19283F;
  background-repeat: no-repeat;
  &--light {
    background-color: $brand-primary;
    padding-bottom: 4rem;
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }
  &--dark {
    background-color: #0094E6;
    padding-bottom: 4rem;
  }
}

.social-icon-link {
  opacity: 1;
  transition: opacity 0.2s;
  &:active,
  &:focus,
  &:hover {
    opacity: 0.6;
  }
}

.job-list {
  position: relative;
  display: none;
  &.active {
    display: block;
  }
}

#overview {
  background: $brand-primary;
  /*background-image: url(/images/bg-pattern-1.png); /* fallback */
  background-position: center center;
  background-size: cover;
}

.avatar-img {
  position: absolute;
  &-1 {
    right: 500px;
    width: 80px;
    top: 470px;
    transform: rotate(20deg);
  }
  &-2 {
    right: 390px;
    top: 380px;
    width: 100px;
    transform: rotate(-20deg);
  }
  &-3 {
    right: 581px;
    top: 380px;
    width: 86px;
    transform: rotate(10deg);
  }
  &-4 {
    left: 440px;
    top: 1350px;
    width: 80px;
    -webkit-transform: rotate(20deg);
    transform: rotate(-20deg);
    @media (max-width: 1280px) {
      left: 380px;
      top: 1510px;
    }
  }
  &-5 {
    left: 520px;
    top: 1480px;
    width: 100px;
    -webkit-transform: rotate(20deg);
    transform: rotate(-20deg);
    @media (max-width: 1280px) {
      left: 390px;
      top: 1370px;
    }
  }
  &-6 {
    left: 410px;
    top: 1460px;
    width: 80px;
    transform: rotate(30deg);
    @media (max-width: 1280px) {
      left: 269px;
    }
  }
  &-7 {
    right: 510px;
    top: 400px;
    width: 80px;
    -webkit-transform: rotate(20deg);
    transform: rotate(-20deg);
    z-index: 9999;
  }
  &-8 {
    right: 400px;
    top: 480px;
    width: 100px;
    transform: rotate(10deg);
    z-index: 9999;
  }
  &-9 {
    right: 540px;
    top: 570px;
    width: 74px;
    transform: rotate(-20deg);
    z-index: 9999;
  }
}

.p-b-0 {
  padding-bottom: 0 !important;
  @media (max-width: $screen-xs-max) {
    padding-bottom: 0 !important
  }
}

.ovbg {
  position: absolute;
  &-1 {
    top: 0;
    right: 0;
    z-index: 0;
  }
  &-2 {
    top: 230px;
    left: -50px;
    opacity: 0.6;
    z-index: 0;
  }
  &-3 {
    top: 540px;
    right: -180px;
    opacity: 0.6;
    z-index: 0;
  }
  &-4 {
    top: 20px;
    right: -20px;
    opacity: 0.6;
    z-index: 0;
  }
  &-5 {
    bottom: 240px;
    right: -10px;
    opacity: 0.6;
    z-index: 0;
  }
  &-6 {
    top: -150px;
    left: 0;
    opacity: 0.6;
    z-index: 0;
  }
}

.tbg {
  position: absolute;
  &-1 {
    top: 0;
    right: 0;
    z-index: 0;
  }
  &-2 {
    bottom: 0;
    left: -20px;
    opacity: 0.6;
    z-index: 0;
  }
  &-3 {
    top: 170px;
    right: 660px;
    opacity: 0.6;
    z-index: 0;
  }
  &-4 {
    top: 20px;
    right: -20px;
    opacity: 0.6;
    z-index: 0;
  }
  &-5 {
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
  }
  &-6 {
    bottom: 40px;
    right: -20px;
    opacity: 0.6;
    z-index: 0;
  }
}

.tmbg {
  position: absolute;
  &-1 {
    top: 0;
    right: 0;
    z-index: 0;
  }
  &-2 {
    bottom: 1210px;
    left: -20px;
    opacity: 0.6;
    z-index: 0;
    @media (max-width: 768px) {
      bottom: 1370px;
      width: 230px;
    }
  }
  &-3 {
    top: 1370px;
    right: 0;
    opacity: 0.6;
    z-index: 0;
  }
  &-4 {
    top: 600px;
    right: -20px;
    opacity: 0.6;
    z-index: 0;
  }
  &-5 {
    top: 440px;
    left: -40px;
    opacity: 0.7;
    z-index: 0;
  }
  &-6 {
    bottom: 2000px;
    left: 780px;
    opacity: 0.6;
    z-index: 0;
  }
}

.ov-xs {
  @media (max-width: $screen-xs-max) {
    padding: 20px 30px;
  }
}

.ov-xs-1 {
  @media (max-width: $screen-xs-max) {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.ov-1st {
  margin: 40px auto 30px;
  display: flex;
  justify-content: baseline;
  padding-left: 100px;
  @media (max-width: $screen-xs-max) {
    padding-left: 30px;
    margin-top: 0;
  }
}

.ov-description {
  &--1 {
    padding-left: 70px;
  }
}

.career-title,
.recruitment-header {
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #0fc8ff;
  border-color: #4a90e2;
  padding: 22px 38px;
  font-size: 21px;
  line-height: 1.15;
  border-radius: 3px;
  box-shadow: 0 4px 0 0 rgba(3, 160, 194, 0.5);
}

.list-inline.footer-list {
  float: right;
}

.hacked-visibility {
  .medium {
    display: none;
  }
  .large {
    display: none;
  }
  .small {
    display: block;
    margin: 0 auto;
  }
  @media (min-width: $screen-sm-min) {
    .small {
      display: none;
    }
    .medium {
      display: block;
      margin: 60px 0 0 75px;
    }
  }
  @media (min-width: $screen-md-min) {
    .small {
      display: none;
    }
    .medium {
      display: none;
    }
    .large {
      display: block;
      margin-left: 130px;
    }
  } // @media (min-width: $screen-lg-min) {
  //   .small{
  //     display: none;
  //   }
  //   .medium{
  //     display: none;
  //   }
  //   .large{
  //     display: block;
  //   }
  // }
}

#applicant {
  display: block;
  &.active {
    display: none;
  }
}

.inquiryForm {
  &.active {
    display: none;
  }
}

#application-received,
.contact-received {
  @include img-retina( '../images/sent-icon.png',
  '../images/sent icon@2x.png',
  90px,
  90px);
  position: relative;
  display: none;
  border: 2px solid #fff;
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center 25px;
  padding: 25px;
  margin-bottom: 20px;
  &.active {
    display: block;
  }
  &::before {
    font-size: 21px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "Your application has been sent. We'll be in touch soon!";
    padding-bottom: 25px;
    display: block;
    text-align: center;
    user-select: none;
  }
}

.contact-received {
  &::before {
    content: "Your demo request has been sent. We'll be in touch soon!";
  }
}

.banner-content {
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 1280px) {
    margin-top: 0;
  }
  &--center {
    margin-left: 12%;
  }
  &--left {
    margin-left: 5%;
  }
}

.large-ov-photo {
  padding-top: 0;
  &-2 {
    padding-left: 200px;
  }
}

.overview-shadow {
  margin-left: 0 !important;
}

// #main-video{
//
//   &-youtube{
//     width: 205px;
//     height: 364px;
//   }
//   //width='410', height='728',
// }
.section-bg-dark {
  position: absolute;
  color: #fff;
  background-repeat: space;
  &--dark {
    //@include img-retina('../images/bg-pattern-1@2x.png', '../images/bg-pattern-1@2x.png', 1366px, 1047px);
    background-color: #2A3E61; //background-position: left top;
    padding-bottom: 0;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tgif-line {
  @extend .flex-center;
  height: 100vh;
  @media screen and (min-width: 300px) and (max-width: 768px) {
    height: auto;
  }
  &__download,
  &__header-title,
  &__item,
  &__or {
    color: white;
  }
  &__header-title,
  &__title {
    font-weight: 400;
  }
  &__or,
  &__size {
    opacity: 0.5;
  }
  &__wrapper {
    height: 100vh;
    @media screen and (min-width: 300px) and (max-width: 768px) {
      height: auto;
      padding: 100px 30px 50px;
    }
  }
  &__main {
    @extend .flex-center;
    position: relative;
    margin-top: 50px;
    @media screen and (min-width: 300px) and (max-width: 768px) {
      margin: 0;
      flex-direction: column;
    }
  }
  &__bg {
    @extend .flex-center;
    height: 100vh;
    opacity: 0.1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__bg__img {
    @media screen and (min-width: 300px) and (max-width: 768px) {
      width: 75%;
    }
  }
  &__item {
    @extend .flex-center;
    flex-direction: column;
    text-align: center;
    z-index: 999;
  }
  &__img {
    width: 100%;
    height: auto;
    max-width: 127px;
  }
  &__title {
    font-size: 1.5em;
    line-height: 10px;
    margin-top: 30px;
  }
  &__header-title {
    font-size: 2em;
    margin: 0;
    position: absolute;
    top: -70px;
    @media screen and (min-width: 300px) and (max-width: 768px) {
      position: static;
      top: 0;
      margin-bottom: 60px;
    }
  }
  &__or {
    margin: 0 60px 100px;
    font-size: 1.5em;
    @media screen and (min-width: 300px) and (max-width: 768px) {
      margin: 50px 20px;
    }
  }
  &__size {
    margin-bottom: 40px;
  }
  &__download {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background: $btn-primary-bg;
    padding: 10px 50px;
    font-size: 1.25em;
    transition: background 200ms ease-in;
    &:focus,
    &:hover {
      background: $btn-primary-dark-bg;
      color: white;
    }
  }
}

.headline-logo {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.logotext {
  margin: 26px 20px 10px 24px;
  font-weight: 400;
  &--light {
    font-weight: 200;
  }
  @media (max-width: $screen-sm-min) {
    font-size: 42px;
    margin: 10px 20px 10px 24px;
  }
}

.highlights {
  margin: 80px 100px 0;
  text-align: center;
  &--1 {}
  &--2 {
    padding-top: 40px;
  }
  &--3 {
    padding-top: 6px;
  }
  p {
    text-align: center;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
  }
}

.high-thousands {
  width: 135px;
  bottom: 20px;
  position: relative;
}

.high-images {
  width: 80px;
}

.slideshow-container {
  margin-left: 0;
}

.testimonial {
  &-section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 0px;
    min-height: 500px; // background-image: url('../images/jumbotron-bg-2.png');
    background-color: rgba(28, 51, 90, 0.1);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  &-buttons {
    margin-top: 20px;
  }
  &-testi-text {
    font-size: 20px;
    color: rgba(28, 51, 90, 0.70);
    text-shadow: none;
    line-height: 30px;
    font-weight: 400;
  }
  &-section .section-title {
    margin-top: 0 !important;
  }
  &-section .company-fade {
    position: absolute;
    bottom: 0;
  }
  &__details {
    display: flex;
    align-items: center;
    max-width: 48%;
    margin: 40px auto 0;
  }
  &__story {
    color: white;
  }
  &__logo {
    margin-right: 70px;
  }
  &__logo img {
    width: 210px;
    height: 210px;
    border-radius: 50%;
  }
  &__logo-barb img {
    width: 315px;
    height: auto;
    border-radius: 0;
    padding-right: 50px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__contact p {
    margin-bottom: 0;
    color: rgba(28, 51, 90, 0.70);
  }
  &__contact p:first-child {
    font-weight: 600;
  }
  &__c2a {
    font-size: 1.2em;
    font-weight: 500;
    border-radius: 2px;
    border: 1px solid white;
    color: white;
    background: $brand-primary;
    text-align: center;
    padding: 10px 30px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.6);
  }
  &__c2a:hover {
    color: rgba(white, 0.9);
    text-decoration: none;
  }
  &-next,
  &-prev {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    background: transparent;
    border: 0;
    z-index: 9999;
  }
  &-prev {
    left: 50px;
  }
  &-next {
    right: 50px;
  }
  @media screen and (min-width: 319px) and (max-width: 1024px) {
    &-section {
      padding-bottom: 50px;
    }
    &__details {
      flex-direction: column;
    }
    &__logo {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &__logo img {
      width: 150px;
      height: 150px;
    }
    &__footer {
      flex-direction: column;
      justify-content: center;
    }
    &__contact {
      text-align: center;
      margin-bottom: 20px;
    }
    &__button-1 {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    &__story h3 {
      text-align: center;
    }
  }
  @media screen and (min-width: 319px) and (max-width: 666px) {
    &-next,
    &-prev {
      display: none;
    }
    &__story h3 {
      text-align: center;
      font-size: 1.3em;
    }
    @media (min-width: 320px) {
      font-size: 1.0em;
    }
  }
}

.store-badges {
  // width: 80%;
  // height: 80%;
  margin-left: 14px;
  &--btn-img-apple {
    width: 100%;
    max-width: 150px;
    @media screen and (max-width: 1280px) {
      max-width: 120px;
    }
  }
  &--btn-img-google {
    width: 100%;
    max-width: 180px;
    @media screen and (max-width: 1280px) {
      max-width: 120px;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 130px;
  }
}

.homepage-btn-grp {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .download-btns {
      margin-top: 20px;
    }
  }
}

.download-btns {
  display: block;
  align-items: center;
  margin-top: 0;
  @media (max-width: $screen-xs-max) {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  @media (max-width: $screen-sm-max) {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.testimonial__contact {
  font-size: 20px;
  min-width: 300px;
  @media (max-width: 320px) {
    font-size: 16px;
  }
}

.lock-scroll {
  height: 600px !important;
  overflow: hidden !important;
}

.header-detail {
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 100px;
  }
  @media (max-width: $screen-xs-max) {
    margin-top: 40px;
  }
}

.headr-form {
  padding: 0 90px 0 10px;
  @media (max-width: $screen-xs-max) {
    padding: 0 12px 0 10px;
  }
  .forms.create {
    .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_b3fb3a02-8ee6-4205-a5da-268d8b497ab8 label {
      font-size: 30px !important;
    }
  }
  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    display: none;
  }
  .hs-form-booleancheckbox-display {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: white !important;
    padding-left: 6px !important;
    font-weight: 400;
  }
  .hs-form-20aa6134-e352-45cc-8db7-75699f8f8da3_35aadf8e-ed64-41c4-a780-c874d590e1ab .inputs-list {
    padding-left: 10px;
  }
  .hs_subscribe_to_the_blog.hs-subscribe_to_the_blog.hs-fieldtype-booleancheckbox.field.hs-form-field {
    margin-bottom: 5px !important;
  }
  .hidden-mac {
    opacity: 0;
    padding-top: 160px;
    @media screen and (max-width: 1280px) {
      opacity: 1;
    }
  }
  .hidden-1080 {
    opacity: 1;
    @media screen and (max-width: 1280px) {
      opacity: 0;
    }
  }
  .hs-input {
    background-color: #fff !important;
    border: 2px solid #e0e6e8 !important;
    border-radius: 3px !important;
    height: 40px !important;
    line-height: normal !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 16px !important;
    transition: border .15s !important;
    width: 100% !important;
    font-size: 16px !important;
    color: #2A3E61 !important;
    font-family: 'Roboto', sans-serif !important;
    box-shadow: none;
    @media (max-width: $screen-xs-max) {
      // padding: 20px 30px;
    }
    &:focus {
      border: 2px solid $brand-primary !important;
      text-decoration: none !important;
      outline: none !important;
      border-color: transparent;
      box-shadow: none;
    }
  }
  .hs_submit.hs-submit {
    margin-top: -30px !important;
  }
  .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_ee29bf7a-0557-40a7-80e6-134c3007898d.actions {
    margin-top: 0 !important;
  }
  .hs-button.primary.large {
    position: relative !important;
    display: inline-block !important;
    background-color: #1BB4FF !important;
    padding: 20px 40px 20px !important;
    margin: 0 0 0 10px !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
    border-radius: 4px !important;
    -webkit-font-smoothing: antialiased !important;
    transition: all .22s ease-in-out !important;
    text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50) !important;
    width: auto !important;
    height: 10px; // line-height: 32px !important;
    letter-spacing: 0.5px !important;
    width: auto !important;
    border: 0 !important;
    background-image: none !important;
    @media (max-width: $screen-xs-max) {
      padding: 20px;
      margin-top: 30px !important;
      margin-left: 36px !important;
    }
    &:hover {
      color: #fff !important;
      transform: translateY(-2px) !important;
      box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
      filter: brightness(110%) !important;
    }
    &:focus {
      color: white !important;
      text-decoration: none !important;
    }
  }
  .submitted-message {
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #2A3E61 !important;
    font-weight: 400;
    text-align: center;
    opacity: 0.5;
    line-height: 208px;
  }
  input.hs-input[type=checkbox],
  input.hs-input[type=radio] {
    box-shadow: none !important;
    width: auto !important;
    height: auto !important;
    margin-right: 10px !important;
    &:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    margin-bottom: 10px;
    padding: 0 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: #bfbfbf !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    margin-bottom: 10px;
    padding: 0 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: #bfbfbf !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_phone.hs-phone.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    padding: 0 10px;
    margin-bottom: 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: #bfbfbf !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    padding: 0 10px;
    margin-bottom: 10px;
    span {
      font-size: 16px !important;
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: #bfbfbf !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  ul.hs-error-msgs.inputs-list {
    li {
      label {
        color: #bfbfbf !important;
      }
    }
  }
}

.forms.create {
  .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_b3fb3a02-8ee6-4205-a5da-268d8b497ab8 label {
    font-size: 30px !important;
  }
}

.hs-form-booleancheckbox-display {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #bfbfbf !important;
  padding-left: 6px !important;
  font-weight: 400;
}

.hs-form-20aa6134-e352-45cc-8db7-75699f8f8da3_35aadf8e-ed64-41c4-a780-c874d590e1ab .inputs-list {
  padding-left: 10px;
}

.hs_subscribe_to_the_blog.hs-subscribe_to_the_blog.hs-fieldtype-booleancheckbox.field.hs-form-field {
  margin-bottom: 5px !important;
}

.hidden-mac {
  opacity: 0;
  padding-top: 160px;
  @media screen and (max-width: 1280px) {
    opacity: 1;
  }
}

.hidden-1080 {
  opacity: 1;
  @media screen and (max-width: 1280px) {
    opacity: 0;
  }
}

.hs-input {
  background-color: #fff !important;
  border: 2px solid #e0e6e8 !important;
  border-radius: 3px !important;
  height: 40px !important;
  line-height: normal !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 16px !important;
  transition: border .15s !important;
  width: 100% !important;
  font-size: 16px !important;
  color: #2A3E61 !important;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: none;
  @media (max-width: $screen-xs-max) {
    // padding: 20px 30px;
  }
  &:focus {
    border: 2px solid $brand-primary !important;
    text-decoration: none !important;
    outline: none !important;
    border-color: transparent;
    box-shadow: none;
  }
}

.hs_submit.hs-submit {
  margin-top: -30px !important;
}

.hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_ee29bf7a-0557-40a7-80e6-134c3007898d.actions {
  margin-top: 0 !important;
}

.hs-button.primary.large {
  position: relative !important;
  display: inline-block !important;
  background-color: #1BB4FF !important;
  padding: 20px 40px 20px !important;
  margin: 20px 0 0 10px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
  border-radius: 4px !important;
  -webkit-font-smoothing: antialiased !important;
  transition: all .22s ease-in-out !important;
  text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50) !important;
  width: auto !important;
  height: 10px; // line-height: 32px !important;
  letter-spacing: 0.5px !important;
  width: auto !important;
  border: 0 !important;
  background-image: none !important;
  @media (max-width: $screen-xs-max) {
    padding: 20px;
    margin-top: 30px !important;
    margin-left: 36px !important;
  }
  &:hover {
    color: #fff !important;
    transform: translateY(-2px) !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
    filter: brightness(110%) !important;
  }
  &:focus {
    color: white !important;
    text-decoration: none !important;
  }
}

.submitted-message {
  font-size: 18px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #2A3E61 !important;
  font-weight: 400;
  text-align: center;
  opacity: 0.5;
  line-height: 208px;
}

input.hs-input[type=checkbox],
input.hs-input[type=radio] {
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  margin-right: 10px !important;
  &:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 10px;
  padding: 0 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 10px;
  padding: 0 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_phone.hs-phone.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 0 10px;
  margin-bottom: 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 0 10px;
  margin-bottom: 10px;
  span {
    font-size: 16px !important;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_message.hs-message.hs-fieldtype-textarea.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 0 10px;
  margin-bottom: 10px;
  span {
    font-size: 16px !important;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_message.hs-message.hs-fieldtype-textarea.field.hs-form-field {
  padding: 10px;
}


ul.hs-error-msgs.inputs-list {
  li {
    label {
      color: #bfbfbf !important;
    }
  }
}

.forms.create {
  .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_b3fb3a02-8ee6-4205-a5da-268d8b497ab8 label {
    font-size: 30px !important;
  }
}

.hs-form-booleancheckbox-display {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #bfbfbf !important;
  padding-left: 6px !important;
  font-weight: 400;
}

.hs-form-20aa6134-e352-45cc-8db7-75699f8f8da3_35aadf8e-ed64-41c4-a780-c874d590e1ab .inputs-list {
  padding-left: 10px;
}

.hs_subscribe_to_the_blog.hs-subscribe_to_the_blog.hs-fieldtype-booleancheckbox.field.hs-form-field {
  margin-bottom: 5px !important;
}

.hidden-mac {
  opacity: 0;
  padding-top: 160px;
  @media screen and (max-width: 1280px) {
    opacity: 1;
  }
}

.hidden-1080 {
  opacity: 1;
  @media screen and (max-width: 1280px) {
    opacity: 0;
  }
}

.hs-input {
  background-color: #fff !important;
  border: 2px solid #e0e6e8 !important;
  border-radius: 3px !important;
  height: 40px !important;
  line-height: normal !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 16px !important;
  transition: border .15s !important;
  width: 100% !important;
  font-size: 16px !important;
  color: #2A3E61 !important;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: none;
  @media (max-width: $screen-xs-max) {
    // padding: 20px 30px;
  }
  &:focus {
    border: 2px solid $brand-primary !important;
    text-decoration: none !important;
    outline: none !important;
    border-color: transparent;
    box-shadow: none;
  }
}

.hs_submit.hs-submit {
  margin-top: -30px !important;
}

.hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_ee29bf7a-0557-40a7-80e6-134c3007898d.actions {
  margin-top: 0 !important;
}

.hs-button.primary.large {
  position: relative !important;
  display: inline-block !important;
  background-color: #1BB4FF !important;
  padding: 20px 40px 20px !important;
  margin: 20px 0 0 10px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
  border-radius: 4px !important;
  -webkit-font-smoothing: antialiased !important;
  transition: all .22s ease-in-out !important;
  text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50) !important;
  width: auto !important;
  height: 10px; // line-height: 32px !important;
  letter-spacing: 0.5px !important;
  width: auto !important;
  border: 0 !important;
  background-image: none !important;
  @media (max-width: $screen-xs-max) {
    padding: 20px;
    margin-top: 30px !important;
    margin-left: 36px !important;
  }
  &:hover {
    color: #fff !important;
    transform: translateY(-2px) !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
    filter: brightness(110%) !important;
  }
  &:focus {
    color: white !important;
    text-decoration: none !important;
  }
}

.submitted-message {
  font-size: 18px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #2A3E61 !important;
  font-weight: 400;
  text-align: center;
  opacity: 0.5;
  line-height: 208px;
}

input.hs-input[type=checkbox],
input.hs-input[type=radio] {
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  margin-right: 10px !important;
  &:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 10px;
  padding: 0 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 10px;
  padding: 0 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_phone.hs-phone.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 0 10px;
  margin-bottom: 10px;
  span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

.hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 0 10px;
  margin-bottom: 10px;
  span {
    font-size: 16px !important;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #bfbfbf !important;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 0.5px;
  }
}

ul.hs-error-msgs.inputs-list {
  li {
    label {
      color: #bfbfbf !important;
    }
  }
}

.contct-form {
  .forms.create {
    .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_b3fb3a02-8ee6-4205-a5da-268d8b497ab8 label {
      font-size: 30px !important;
    }
  }
  .hs-form-booleancheckbox-display {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    color: rgba(43, 80, 121, 0.6) !important;
    padding-left: 6px !important;
    font-weight: 400;
  }
  .hs-form-20aa6134-e352-45cc-8db7-75699f8f8da3_35aadf8e-ed64-41c4-a780-c874d590e1ab .inputs-list {
    padding-left: 10px;
  }
  .hs_subscribe_to_the_blog.hs-subscribe_to_the_blog.hs-fieldtype-booleancheckbox.field.hs-form-field {
    margin-bottom: 5px !important;
  }
  .hidden-mac {
    opacity: 0;
    padding-top: 160px;
    @media screen and (max-width: 1280px) {
      opacity: 1;
    }
  }
  .hidden-1080 {
    opacity: 1;
    @media screen and (max-width: 1280px) {
      opacity: 0;
    }
  }
  .hs-input {
    background-color: #fff !important;
    border: 2px solid #e0e6e8 !important;
    border-radius: 3px !important;
    height: 40px !important;
    line-height: normal !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 16px !important;
    transition: border .15s !important;
    width: 100% !important;
    font-size: 16px !important;
    color: #2A3E61 !important;
    font-family: 'Roboto', sans-serif !important;
    box-shadow: none;
    @media (max-width: $screen-xs-max) {
      // padding: 20px 30px;
    }
    &:focus {
      border: 2px solid $brand-primary !important;
      text-decoration: none !important;
      outline: none !important;
      border-color: transparent;
      box-shadow: none;
    }
  }
  .hs_submit.hs-submit {
    margin-top: -30px !important;
  }
  .hs-form-ccdac51c-c088-4e7b-98c1-fa9276df1aed_ee29bf7a-0557-40a7-80e6-134c3007898d.actions {
    margin-top: 0 !important;
  }
  .hs-button.primary.large {
    position: relative !important;
    display: inline-block !important;
    background-color: #1BB4FF !important;
    padding: 20px 40px 20px !important;
    margin: 20px 0 0 10px !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
    border-radius: 4px !important;
    -webkit-font-smoothing: antialiased !important;
    transition: all .22s ease-in-out !important;
    text-shadow: 0 2px 4px rgba(73, 143, 225, 0.50) !important;
    height: 10px; // line-height: 32px !important;
    letter-spacing: 0.5px !important;
    width: auto !important;
    border: 0 !important;
    background-image: none !important;
    @media (max-width: 1280px) {
      width: 86% !important;
    }
    @media (max-width: $screen-xs-max) {
      width: 83% !important;
      margin: 0 auto !important;
      margin-left: 7px !important;
    }
    &:hover {
      color: #fff !important;
      transform: translateY(-2px) !important;
      box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08) !important;
      filter: brightness(110%) !important;
    }
    &:focus {
      color: white !important;
      text-decoration: none !important;
    }
  }
  .submitted-message {
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #2A3E61 !important;
    font-weight: 400;
    text-align: center;
    opacity: 0.5;
    line-height: 208px;
  }
  input.hs-input[type=checkbox],
  input.hs-input[type=radio] {
    box-shadow: none !important;
    width: auto !important;
    height: auto !important;
    margin-right: 10px !important;
    &:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    margin-bottom: 10px;
    padding: 0 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: rgba(43, 80, 121, 0.6) !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    margin-bottom: 10px;
    padding: 0 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: rgba(43, 80, 121, 0.6) !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_phone.hs-phone.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    padding: 0 10px;
    margin-bottom: 10px;
    span {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: rgba(43, 80, 121, 0.6) !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;
    padding: 0 10px;
    margin-bottom: 10px;
    span {
      font-size: 16px !important;
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
      color: rgba(43, 80, 121, 0.6) !important;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }
  ul.hs-error-msgs.inputs-list {
    li {
      label {
        color: rgba(43, 80, 121, 0.6) !important;
      }
    }
  }
}

.p-left-10 {
  padding-left: 10px;
}

.tools-items {
  padding: 0;
}

.tools-detail {
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 80px;
  }
}

.prefooter-detail {
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    line-height: normal;
  }
}

.overview-detail {
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: -100px;
  }
}

.ft-body-text {
  text-align: left;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-top: 10px;
  -webkit-font-smoothing: antialiased;
}

.ov-h2-text {
  font-size: 24px;
  &--left-align {
    @media screen and (max-width: 768px) {
      text-align: left;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
  &--right-align {
    @media screen and (max-width: 768px) {
      text-align: right;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
  &--p-left {
    @media screen and (max-width: 768px) {
      font-size: 18px;
      text-align: left;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
  &--p-right {
    @media screen and (max-width: 768px) {
      font-size: 18px;
      text-align: right;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
}

.slider-iphone {
  width: 265px;
  height: 470px;
  margin: 50px 0 0 5px;
}

.homepage-overview-image {
  // @include img-retina('../images/exciting-features-screen.png', '../images/exciting-features-screen@2x.png', 270px, 470px);
  background: transparent no-repeat center right;
  width: 270px;
  height: 470px;
  position: relative;
  border: 0 !important; //border: 10px solid rgba(255, 255, 255, 0.3);
  @media (max-width: $screen-xs-max) {
    box-sizing: content-box;
    border-radius: 3px;
    margin: 0 auto 60px;
  }
  @media (min-width: $screen-sm-min) {
    // @include img-retina('../images/exciting-features-image.png', '../images/exciting-features-image.png', 683px, 714px);
    background-repeat: no-repeat;
    background-position: center right;
    width: 100%;
    height: 718px;
    &:after {
      content: "";
      display: block; // @include img-retina('../images/exciting-features-screen.png', '../images/exciting-features-screen@2x.png', 205px, 364px);
      background-repeat: no-repeat;
      background-position: top left;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 57px auto 0 297px;
    }
    #main-video-youtube,
    video {
      position: absolute;
      left: auto;
      top: 57px;
    }
  }
  @media (min-width: $screen-md-min) {
    background-position: center right;
    &:after {
      background-position: top right;
      margin: 57px 79px 0 auto;
    }
    #main-video-youtube,
    video {
      position: absolute;
      right: 164px;
      left: auto;
      top: 57px;
    }
  }
}

.ov-top-margin-xs {
  @media (max-width: $screen-xs-max) {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.rm-m-top {
  @media (max-width: 425px) {
    margin-top: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.rm-p-bottom {
  @media (max-width: 425px) {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.scr-size-xs {
  @media (max-width: 425px) {
    width: 295px !important;
  }
}

.ov-feature-m-top {
  @media (max-width: 768px) {
    margin-top: 100px !important;
  }
  @media (max-width: 1024px) {
    margin-left: 80px !important;
  }
  @media (max-width: 320px) {
    margin-left: auto !important;
  }
}

.toggle-icon {
  display: inline-block !important;
  float: right;
}

.handvid-p {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (max-width: 320px) {
    padding-right: 0;
  }
}

.pricing-title {
  margin: 0 auto;
  @media (max-width: $screen-xs-max) {
    display: flex;
    justify-content: center;
  }
}

.pricing-desc {
  color: white;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
  &--foot {
    text-align: center !important;
    color: white;
    font-weight: 400;
    @media (max-width: 1280px) {
      font-size: 16px;
    }
  }
  &--cont {
    cursor: pointer;
    text-decoration: underline;
    color: $brand-primary;
  }
}

.pricing-table-details {
  width: 1000px;
  &-1 {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-right: 60px;
    .in-store-item {
      width: 100%;
      padding: 0 90px;
    }
  }
}

.additional-text {
  font-size: 14px;
  text-align: start;
  letter-spacing: 0.5px;
  color: #2B5079;
  @media (max-width: 425px) {
    text-align: center;
    width: 350px;
    margin: auto;
    padding: 40px 0;
  }
}

.package-details-header {
  background-color: white;
  height: auto;
  width: 100%;
  margin-left: 0;
  border-radius: 4px 4px 0 0;
  display: inline-block;
  flex-direction: column;
  line-height: 0;
  padding-bottom: 22px; // @media (max-width: $screen-xs-max) {
  //   table, thead, tbody, th, td, tr {
  //     display: block;
  //   }
  //   thead tr {
  //     // position: absolute;
  //     // top: -9999px;
  //     // left:-9999px;
  //   }
  //   tr {
  //     border: 1px solid gainsboro;
  //   }
  //   td {
  //     border: none;
  //     border-bottom: 1px solid gainsboro;
  //     position: relative;
  //     padding-left: 50%;
  //   }
  //   td:before {
  //     position: absolute;
  //     top: 6px;
  //     left: 6px;
  //     width: 45%;
  //     padding-right: 10px;
  //     white-space: nowrap;
  //   }
  // }
  table {
    border-collapse: collapse;
    width: 100%;
    @media (max-width: 425px) {
      width: 350px;
      margin: auto;
    }
    @media (max-width: 768px) and (min-width: 426px) {
      width: 700px;
      margin: auto;
    }
  }
  table,
  td {
    border: 1px solid #2B5079;
  }
  th {
    background-color: #2B5079;
    font-size: 14px;
    color: white;
    border: 1px solid #2B5079;
    height: 50px;
    padding-left: 30px;
    -webkit-font-smoothing: antialiased;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      padding: 20px;
      line-height: 20px;
    }
  }
  td {
    text-align: start;
    font-size: 14px;
    color: #2B5079;
    height: 60px;
    padding-left: 30px;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      padding: 20px;
      line-height: 18px;
    }
  }
  .small {
    font-size: 12px;
  }
  .medium {
    font-size: 14px;
    line-height: 14px;
  }
  .light {
    background: #1AB5FF;
  }
  &--1 {
    width: 33%;
    display: inline-block;
    margin: 0;
    font-size: 16px !important;
    color: white !important;
    padding-top: 0 !important;
  }
  &--2 {
    width: 33%;
    display: inline-block;
    margin: 0;
    font-size: 16px !important;
    color: white !important;
    padding-top: 0 !important;
  }
  &--3 {
    width: 33%;
    display: inline-block;
    margin: 0;
    font-size: 16px !important;
    color: white !important;
    padding-top: 0 !important;
  }
}

.pricing-title-container-solo-1 {
  background-color: #2B5079;
  height: auto;
  width: 100%;
  margin-left: 90px;
  padding: 25px 30px 10px;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }
  &--license {
    float: left;
    font-size: 14px !important;
    color: white !important;
    padding-top: 0 !important;
    opacity: 0.5;
    display: flex; // text-align: left;
    justify-content: flex-start;
    margin-top: 0;
  }
  &--price {
    margin: 0;
    font-weight: 300;
    font-size: 32px !important;
    color: white !important;
    padding-top: 0 !important;
    letter-spacing: 1.33px;
    line-height: 1.2;
    display: flex;
    float: right;
    text-align: right;
    justify-content: space-between;
  }
  &--description {
    float: right;
    font-size: 14px !important;
    color: white !important;
    padding-top: 0 !important;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    text-align: right;
  }
  &--header {
    margin: 0;
    font-weight: 300;
    font-size: 32px !important;
    padding-top: 0 !important;
    color: white !important;
    letter-spacing: 1.33px;
    line-height: 1.2;
    float: left;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
  &--head-desc {
    font-weight: 300;
  }
}

.pricing-title-container-solo-2 {
  background-color: #1AB5FF;
  height: auto;
  width: 100%;
  margin-left: 90px;
  padding: 25px 30px 10px;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  @media (max-width: $screen-xs-max) {
    margin-left: 0;
    margin-top: 20px;
  }
  &--license {
    float: left;
    font-size: 14px !important;
    color: white !important;
    padding-top: 0 !important;
    opacity: 0.5;
    display: flex; // text-align: left;
    justify-content: flex-start;
    margin-top: 0;
  }
  &--price {
    margin: 0;
    font-weight: 300;
    font-size: 32px !important;
    color: white !important;
    padding-top: 0 !important;
    letter-spacing: 1.33px;
    line-height: 1.2;
    display: flex;
    float: right;
    text-align: right;
    justify-content: space-between;
  }
  &--description {
    float: right;
    font-size: 14px !important;
    color: white !important;
    padding-top: 0 !important;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    text-align: right;
  }
  &--header {
    margin: 0;
    font-weight: 300;
    font-size: 32px !important;
    padding-top: 0 !important;
    color: white !important;
    letter-spacing: 1.33px;
    line-height: 1.2;
    float: left;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
  &--head-desc {
    font-weight: 300;
  }
}

.pricing-items-li {
  font-size: 18px;
  color: #4F7293;
}

.sign-up-btns {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.pricing-title-container-pro {
  background: #1AB5FF;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(-90deg, #1AB5FF, #13C8FA);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(-90deg, #1AB5FF, #13C8FA);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(-90deg, #1AB5FF, #13C8FA);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(-90deg, #1AB5FF, #13C8FA);
  /* Standard syntax */
  height: auto;
  width: auto;
  padding: 25px 30px 10px;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  &--license {
    float: left;
    font-size: 18px;
    color: white;
    opacity: 0.5;
    display: flex; // text-align: left;
    justify-content: flex-start;
    margin-top: 0;
  }
  &--price {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    color: white;
    letter-spacing: 1.33px;
    line-height: 1.2;
    display: flex;
    float: right;
    text-align: right;
    justify-content: space-between;
  }
  &--description {
    float: right;
    font-size: 18px;
    color: white;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    text-align: right;
  }
  &--header {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    color: white;
    letter-spacing: 1.33px;
    line-height: 1.2;
    float: left;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
  &--head-desc {
    font-weight: 300;
  }
}

.pricing-title-container-enterprise {
  background: #1AB5FF;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(-90deg, #1ECAFE, #1AB5FF);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(-90deg, #1ECAFE, #1AB5FF);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(-90deg, #1ECAFE, #1AB5FF);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(-90deg, #1ECAFE, #1AB5FF);
  /* Standard syntax */
  height: auto;
  width: auto;
  padding: 25px 30px 10px;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  &--license {
    float: left;
    font-size: 18px;
    color: white;
    opacity: 0.5;
    display: flex; // text-align: left;
    justify-content: flex-start;
    margin-top: 0;
  }
  &--price {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    color: white;
    letter-spacing: 1.33px;
    line-height: 1.2;
    display: flex;
    float: right;
    text-align: right;
    justify-content: space-between;
  }
  &--description {
    float: right;
    font-size: 18px;
    color: white;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    text-align: right;
  }
  &--header {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    color: white;
    letter-spacing: 1.33px;
    line-height: 1.2;
    float: left;
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
  &--head-desc {
    font-weight: 300;
  }
}

.how-to-coinspect-text {
  &--header {
    font-size: 20px;
    color: #205081;
    opacity: 0.8;
    line-height: 1.5em;
    padding-bottom: 40px;
  }
  &--p {
    font-size: 14px;
    color: #205081;
    font-weight: 400;
    line-height: 1.5em;
    opacity: 0.8;
  }
}

.ov-background {
  position: absolute;
  &--1 {
    background-image: url('../images/ov-bg-1.png');
    background-repeat: no-repeat;
    background-size: 600px 600px;
    background-position-x: right;
    background-position-y: bottom;
    @media (min-width: 1024px) {
      background-size: 400px 400px;
    }
    @media (max-width: 768px) {
      background-size: 0 0;
    }
  }
  &--2 {
    background-image: url('../images/ov-bg-2.png');
    background-repeat: no-repeat;
    background-size: 300px 300px;
    background-position-x: left;
    background-position-y: bottom;
    @media (min-width: 1024px) {
      background-size: 250px 250px;
    }
    @media (max-width: 768px) {
      background-size: 0 0;
    }
  }
  &--3 {
    background-image: url('../images/ov-bg-3.png');
    background-repeat: no-repeat;
    background-size: 350px 350px;
    background-position-x: right;
    background-position-y: center;
    @media (min-width: 1024px) {
      background-size: 250px 250px;
      background-position-y: bottom;
    }
    @media (max-width: 768px) {
      background-size: 0 0;
    }
  }
  &--4 {
    background-image: url('../images/ov-bg-4.png');
    background-repeat: no-repeat;
    background-size: 400px 400px;
    background-position-x: left;
    background-position-y: center;
    @media (min-width: 1024px) {
      background-size: 300px 300px;
    }
    @media (max-width: 768px) {
      background-size: 0 0;
    }
  }
}

.teamphoto {
  height: 420px;
  .headline-title {
    color: white;
    z-index: 99999;
    display: flex;
    justify-content: center;
    margin: 180px auto 20px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.8px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  }
  .headline-content {
    font-size: 18px;
    color: white;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.8px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  }
}

.brands {
  background-color: white;
  margin-bottom: 80px;
  &--header {
    padding-top: 80px;
    color: #19283F;
    padding-bottom: 20px;
  }
  .brands-support {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    background-color: white;
    width: 100vw;
    &-image {
      opacity: 0.6;
      z-index: auto;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: all .4s ease-in-out;
      @media (max-width: 1564px) {
        max-width: 90%;
        padding: 0 10px;
      }
      &:hover {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 0.8;
        transform: translateY(-2px)
      }
    }
    &-link {
      position: relative;
      display: inline-block;
      z-index: 1;
    }
    @media (min-width: $screen-md-min) {
      padding: 25px 100px;
    }
    @media (min-width: $screen-lg-min) {
      padding: 30px 100px;
    }
  }
}

.brands-support-image {
  opacity: 0.6;
  z-index: auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all .4s ease-in-out;
  @media (max-width: 1564px) {
    max-width: 90%;
    padding: 0 10px;
  }
  @media (max-width: 1280px) {
    max-width: 90%;
    padding: 0;
  }
  &:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 0.8;
    transform: translateY(-2px)
  }
}

.pre-footer {
  background-color: white;
  margin-bottom: 60px;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
    margin-top: 40px;
  }
  &-detail {
    text-align: center;
  }
  &-btn {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }
  h3 {
    font-size: 32px;
    color: #19283F;
    -webkit-font-smoothing: antialiased;
    @media screen and (max-width: 768px) {
      line-height: normal;
    }
  }
  h4 {
    font-size: 20px;
    color: rgba(25, 40, 63, 0.5);
    -webkit-font-smoothing: antialiased;
    line-height: normal;
    @media screen and (max-width: 768px) {
      line-height: normal;
      font-size: 16px;
      padding: 10px 40px;
    }
  }
  .centr {
    @media screen and (max-width: $screen-xs-max) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .free {
    font-size: 20px !important;
    color: #19283F;
    -webkit-font-smoothing: antialiased;
    line-height: normal;
    font-weight: 500 !important;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
      line-height: normal;
      font-size: 16px;
      padding: 10px 40px;
    }
  }
  h5 {
    text-align: start;
    font-size: 18px;
    color: #19283F;
    -webkit-font-smoothing: antialiased;
    line-height: normal;
    display: inline-block;
    @media screen and (max-width: 768px) {
      line-height: normal;
      font-size: 16px;
      padding: 10px 40px;
    }
    @media screen and (max-width: $screen-xs-max) {
      padding: 10px;
      width: 250px;
    }
  }
  &-btn {
    width: 100%;
    margin-top: 14px;
  }
}

.chck-ico {
  position: relative;
  width: 30px;
  display: inline-block;
  padding-right: 0;
  margin-right: 20px;
  @media screen and (max-width: $screen-xs-max) {
    margin-right: 10px;
    width: 20px;
  }
}

.chck-txt {
  text-align: start;
}

.chck-item {
  padding-left: 80px;
  @media screen and (max-width: $screen-xs-max) {
    padding-left: 0 !important;
    text-align: center;
  }
  &-2 {
    padding-left: 0 !important;
    @media screen and (max-width: 768px) {
      padding-left: 80px !important;
    }
    @media screen and (max-width: $screen-xs-max) {
      padding-left: 0 !important;
    }
  }
}

.chck-container {
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer {
  &-logo {
    text-align: center;
    p {
      padding-top: 10px;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      color: white;
      line-height: normal;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
  &-nav-1 {
    display: flex;
    justify-content: center;
  }
  &-sm-logos {
    margin: 30px 10px;
    transition: all .22s ease-in-out;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
      filter: brightness(110%);
      border-radius: 100px;
    }
  }
}

.m-t-50 {
  margin-top: 50px;
}

.success-request {
  .success-title {
    font-size: 46px;
    font-weight: 400;
    color: $brand-primary;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    @media (max-width: 1280px) {
      font-size: 34px;
    }
  }
  .success-desc {
    text-align: center;
    color: #0b0b21;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.8px;
    line-height: normal;
    @media (max-width: 1280px) {
      font-size: 16px;
    }
  }
  .img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 140px;
    @media (max-width: 1280px) {
      width: 100px;
    }
  }
  .sm {
    width: 50px;
  }
  .home-btn {
    margin: 0 420px;
    @media (max-width: 1280px) {
      padding: 10px 40px;
    }
  }
  .conts {
    @media (max-width: 1280px) {
      margin: 7% auto;
    }
  }
}

.center-align {
  text-align: center;
}

.center-align-2 {
  display: flex;
  justify-content: center;
  margin: 40px auto;
  @media (max-width: 1280px) {
    margin: 20px auto;
  }
}

.contact-form-1 {
  background-color: #fff;
  border: 2px solid #e0e6e8;
  border-radius: 3px;
  height: 50px;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  transition: border .15s;
  width: 100%;
  font-size: 16px;
  color: #2A3E61;
  @media (max-width: $screen-xs-max) {
    // padding: 20px 30px;
  }
  &:focus {
    border: 2px solid $brand-primary;
    text-decoration: none;
    outline: none;
  }
}

.contact-form-1::-webkit-input-placeholder {
  color: rgba(#2A3E61, 0.5)
}

.contact-title-1 {
  text-align: center;
  color: #2B5079;
  font-size: 30px;
  margin-top: 0px;
  -webkit-font-smoothing: antialiased;
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}

.contact-sub-1 {
  text-align: center;
  color: rgba(#2B5079, 0.6);
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  &-li {
    color: #1bb4ff;
    text-align: center;
    font-size: 18px;
    @media (max-width: $screen-xs-max) {
      font-size: 14px;
    }
  }
  @media (max-width: $screen-xs-max) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.modal-hdr {
  padding-bottom: 100px;
  @media (max-width: $screen-xs-max) {
    padding-bottom: 10px;
  }
  @media (max-width: 1280px) {
    padding-bottom: 30px;
  }
}

.cancel-ico {
  float: right;
  width: 30px;
  opacity: 0.4;
  cursor: pointer;
  color: #2B5079;
  @media (max-width: $screen-xs-max) {
    width: 20px;
    float: unset;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.faq {
  a {
    color: rgba(25, 40, 63, 0.8);
    &:active {
      color: #1bb4ff;
    }
    &:focus {
      color: #1bb4ff;
    }
  }
  h2 {
    text-align: center !important;
    font-size: 60px;
    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(25, 40, 63, 1);
    -webkit-font-smoothing: antialiased;
    text-align: left;
    display: inline-block;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  h5 {
    font-size: 14px;
    color: rgba(25, 40, 63, 1);
    -webkit-font-smoothing: antialiased;
    text-align: left;
    display: inline-block;
    line-height: normal;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  .caret-down {
    width: 10px;
    display: inline-block;
    position: relative;
    right: 0;
    top: 17px;
    float: right;
  }
  .fa-toggle {
    padding: 20px 30px;
    border: 1px solid gainsboro;
    border-radius: 4px;
    opacity: 0.9;
    margin: 4px 0;
    cursor: pointer;
    transition: all .22s ease-in-out;
    &:hover {
      opacity: 1;
      border: 1px solid #1bb4ff;
    } // &:focus {
    //     opacity: 1;
    //     border: 1px solid #1bb4ff;
    // }
    &:focus-within {
      opacity: 1;
      border: 1px solid #1bb4ff;
    }
  }
  .fa-answer {
    border-top: 1px solid gainsboro;
  }
}

.faq-section {
  background-color: #19283F !important;
  padding-bottom: 0 !important;
  .faq-title {
    font-size: 30px;
    color: white;
  }
  .faq-desc {
    color: white;
    display: inline-block;
    padding-left: 10px;
  }
  .faq-ico {
    display: inline-block;
    margin-right: 20px;
  }
  .faq-form {
    padding-top: 20px;
  }
  .padding-0 {
    padding: 0;
  }
  .faq-address {
    padding-bottom: 10px;
  }
}

.a-title {
  &:hover {
    text-decoration: none;
    opacity: 1;
  }
  &:focus {
    text-decoration: none;
    opacity: 1;
  }
}

.faq-tabs {
  &:focus-within {
    color: #1bb4ff;
  }
}

.header-testi {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto 0px;
  @media (max-width: 1280px) {
    margin-top: 0;
  }
  .header-testi-story {
    text-align: center;
    margin: 0 auto 0;
    @media (max-width: 1280px) {
      margin: 0 auto 0;
    }
  }
  h3 {
    color: white;
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: normal;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
    @media (max-width: 1280px) {
      font-size: 16px;
    }
  }
  p {
    color: white;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    padding-left: 20px;
    font-size: 16px;
    @media (max-width: 1280px) {
      font-size: 16px;
    }
  }
  img {
    width: 60px;
    height: 60px;
    display: inline-block; // border: 2px solid white;
    border-radius: 50%;
    @media (max-width: 1280px) {
      width: 40px;
      height: 40px;
    }
  }
  .ot-img {
    width: 160px;
    text-align: center;
    border: none !important;
    height: auto;
    padding-bottom: 3px;
  }
  .foot {
    margin: 0 auto;
    @media (max-width: 1280px) {
      margin: 0 auto;
    }
  }
  .contact {
    text-align: center;
    margin: 0 auto;
  }
}

.margin-10 {
  margin: 10px !important;
}

.footer-address {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

.first-name {
  padding-left: 0;
}

.last-name {
  padding-right: 0;
  padding-left: 0;
}

.f-checklist-row {
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}

.case-cont {
  text-align: center;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .22s ease-in-out;
  padding: 10px 30px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, .3), 0 3px 6px rgba(0, 0, 0, .08);
  border-top: 6px solid #1bb4ff;
  background: white;
  &--photo {
    position: relative;
    top: 40px;
    width: auto;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: auto;
      max-width: 160px;
    }
  }
  &--photo-container {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #2B5079;
    -webkit-font-smoothing: antialiased;
    width: 200px;
    display: block;
    margin: 20px auto;
  }
  h5 {
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #2B5079;
    opacity: 0.5;
    -webkit-font-smoothing: antialiased;
    padding: 0 18px;
  }
  .case-btn {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .cases-btn {
    padding: 10px 40px 10px !important;
  }
}

.tool-icons {
  text-align: center;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .22s ease-in-out;
  padding: 10px 30px;
  background: transparent;
  &--photo {
    position: relative;
    top: 40px;
    width: auto;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: auto;
      max-width: 90px;
    }
  }
  &--photo-container {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #2B5079;
    -webkit-font-smoothing: antialiased;
  }
  h5 {
    font-weight: normal;
    line-height: normal;
    font-size: 17px;
    letter-spacing: 0.2px;
    color: #2B5079;
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
    padding: 0 18px;
  }
}

.tool-icons-last {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .22s ease-in-out;
  padding: 10px 30px;
  background: transparent;
  &--photo {
    position: relative;
    top: 40px;
    width: auto;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: auto;
      max-width: 650px;
    }
    @media (max-width: $screen-xs-max) {
      width: auto;
      max-width: 350px;
    }
  }
  &--photo-container {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: visible;
    @media (max-width: 768px) {
      height: auto;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #2B5079;
    -webkit-font-smoothing: antialiased;
  }
  h5 {
    font-weight: normal;
    line-height: normal;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #2B5079;
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
    padding: 40px 18px 10px;
  }
}

.case-modal {
  .head-case {
    margin-top: 60px;
  }
}

.advisors-header {
  text-align: center;
  font-size: 32px;
  padding: 60px;
  color: #1bb4ff;
  letter-spacing: 0.8px;
}

.free-trial-nav {
  display: none;
}

.show,
.free-trial-nav:hover {
  display: block;
}

#fixed-form-container {
  position: fixed;
  bottom: 0px;
  left: 3%;
  width: 94%;
  text-align: center;
  margin: 0;
  z-index: 99999;
}

#fixed-form-container .button:before {
  content: url("../images/poplogo.png");
  top: 10px;
  position: relative;
  padding-right: 8px;
}

#fixed-form-container .expanded:before {
  content: url("../images/poplogo.png");
  top: 10px;
  position: relative;
  padding-right: 8px;
}

#fixed-form-container .button {
  font-size: 1.1em;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #309AFF;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 20px 15px 20px;
  background-color: #309AFF;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  &:hover {
    padding: 5px 20px 20px 20px;
  }
}

#fixed-form-container .body {
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #309AFF;
  margin-bottom: 16px;
  padding: 10px;
  padding-right: 26px;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width:768px) {
  #fixed-form-container .button {
    margin: 0;
  }
  #fixed-form-container {
    left: 20px;
    width: 390px;
    text-align: left;
  }
  #fixed-form-container .body {
    padding: 30px;
    border-radius: 0px 5px 5px 5px;
  }
}

.metrics-section {
  background: #19283F;

  .metric-container {
    text-align: center;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .22s ease-in-out;
    padding: 10px 30px;
    background: transparent;

    .metric-photo {
      margin: 0;
    }

    &--photo-container {
      width: 100%;
      height: 150px;
      position: relative;
      overflow: hidden;
    }
    &--photo {
      position: relative;
      width: auto;
      text-align: center;
      margin: 0 auto;
    }
  }

  .title {
    color: #1bb4ff;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
  }
  .features {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  }
}