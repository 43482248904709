/*
    Common
*/

.wizard {
  display: block;
  width: 100%;
  overflow: hidden;
}

.wizard a {
  outline: 0;
}

/* Accessibility */
.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .content > .title {
  position: absolute;
  left: -999em;
}

/*
    Wizard
*/

.wizard > .steps {
  display: none;
}

.wizard > .content {
  background: transparent;
  min-height: 30em;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.wizard > .content > .body {
  &.transit{
    @include clearfix;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
